<template>
  <div class="tooltip-container" ref="tooltipContainer">
    <div class="tooltip-trigger" @click="toggleTooltip" ref="trigger">
      <slot></slot>
    </div>
    <div v-show="isVisible && content" class="tooltip-content" :style="contentStyle" ref="content">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    content: {
      type: Number,
      required: true
    },
    position: {
      type: String,
      default: 'top',
      validator: (value) => ['top', 'bottom', 'left', 'right'].includes(value)
    },
    duration: {
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      isVisible: false,
      contentStyle: {}
    }
  },
  methods: {
    toggleTooltip() {
      if (this.content < 100000) {
        return false;
      }
      this.isVisible = !this.isVisible;
      if (this.isVisible) {
        this.$nextTick(() => {
          this.updatePosition();
        });
      } else {
        clearTimeout(this.hideTimeout);
      }
    },
    updatePosition() {
      if (!this.$refs.trigger || !this.$refs.content) return;

      let left, top;
      switch(this.position) {
        case 'bottom':
          left = 0;
          top = '2rem';
          break;
        case 'left':
          left = '-4rem';
          top = '50%';
          break;
        case 'right':
          left = 0;
          top = '2rem';
          break;
        default: // top
          left = 'auto';
          top = '-92%';
      }

      this.contentStyle = { 
        left: `${left}`, 
        top: `${top}` 
      };
      
      this.hideTimeout = setTimeout(() => {
        this.isVisible = false;
        clearTimeout(this.hideTimeout);
      }, this.duration);
    },
  }
}
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 5;
  word-wrap: break-word;
}
</style>