<template>
  <div class="allwallet" :class="{ 'showAllwallet': showWallet }">
    <div class="modallayer" @click="updateChildValue"></div>
    <ul>
      <li class="wallet" v-for="(item,index) in list" :key="index" @click="updateJump(item)">
        <div class="wallet-img" :style="{ backgroundImage: 'url(' + item.img + ')' }"></div>
        <div class="wallet-name">{{item.name}}</div>
      </li>
    </ul>
    <QRCodeModal ref="qrcodeModal" />
  </div>
</template>

<script>
import QRCodeModal from './QRCodeModal'

const routeUrl = process.env.ROUTE_URL || '../';

export default {
  name: 'AllWallet',
  components: {
    QRCodeModal
  },
  props: {
    showWallet: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: '1'
    }
  },
  data() {
    return {
      userId: '',
      url: process.env.NEXT_PUBLIC_DOMAIN,
      token: localStorage.getItem('token') || '',
      list: [
        {
          name: 'MetaMask',
          wallet: 'MetaMask',
          img: routeUrl + 'assets/img/icons/MetaMask.webp',
          jump: 'https://metamask.app.link/dapp/',
        },
        {
          name: 'WalletConnect',
          wallet: 'WalletConnect',
          img: routeUrl + 'assets/img/icons/WalletConnect.webp',
          jump: '',
        },
        // {
        //   name: 'Trust',
        //   wallet: 'Trust',
        //   img: routeUrl + 'assets/img/icons/Trust.webp',
        //   jump: 'https://link.trustwallet.com/open_url?coin_id=60&url=',
        // },
        {
          name: 'OKX Wallet',
          wallet: 'OKXWallet',
          img: routeUrl + 'assets/img/icons/OKXWallet.webp',
          jump: '',
        },
        // {
        //   name: 'Token Pocket',
        //   wallet: 'TokenPocket',
        //   img: routeUrl + 'assets/img/icons/TokenPocket.webp',
        //   jump: 'https://www.mytokenpocket.vip/dapp/',
        // },
        {
          name: 'Bitget Wallet',
          wallet: 'BitgetWallet',
          img: routeUrl + 'assets/img/icons/BitgetWallet.webp',
          jump: 'https://bkcode.vip?action=dapp&url=',
        },
      ]
    };
  },
  methods: {
    showQRCode() {
      this.$refs.qrcodeModal.show(this.type)
    },
    updateParentValue(value) {
      this.parentValue = value;
    },
    updateChildValue() {
      this.$emit('childClick', false);
    },
    updateJump(item){
      if (item.wallet === 'OKXWallet') {
        const dappUrl = this.url + '/?token=' + this.token + '&type=' + this.type + '&gasPrice=1';
        const encodedDappUrl = encodeURIComponent(dappUrl);
        const deepLink = "okx://wallet/dapp/url?dappUrl=" + encodedDappUrl;
        const encodedUrl = "https://www.okx.com/download?deeplink=" + encodeURIComponent(deepLink);
        Telegram.WebApp.openLink(encodedUrl, '_blank');
        return false;
      } if (item.wallet === 'WalletConnect') {
        this.$emit('childClick', false);
        // this.$emit('walletClick', item.wallet);
        this.showQRCode();
        return false;
      } if (item.wallet === 'BitgetWallet') {
        const dappUrl = '/?token=' + this.token + '/type=' + this.type;
        const newUrl = item.jump + this.url + dappUrl;
        if (this.token) {
          Telegram.WebApp.openLink(newUrl, '_blank');
          return false;
        }
        return false;
      } else {
        const newUrl = item.jump + this.url + '?token=' + this.token + '&type=' + this.type;
        if (this.token) {
          Telegram.WebApp.openLink(newUrl, '_blank');
          return false;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.allwallet{
  width: 100%;height: auto;
  position: fixed;
  bottom: -31rem;
  left: 0;
  z-index: 999;
  background-color: rgba(0,0,0,0.9);
  transition: all .6s ease-in-out;
  .modallayer{
    width: 100vw;height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.5);
    display: none;
  }
  ul{
    position: relative;
    z-index: 1000;
    padding: 1rem;
    li{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      border-radius: 1rem;
      padding: .5rem 0;
      margin-bottom:.6rem;
      background-color: rgba(0,0,0,0.9);
      .wallet-img{
        border-radius: 1rem;
        width: 3rem;height: 3rem;
        background: url(../assets/img/icons/MetaMask.webp) no-repeat center center/100% 100%;
      }
      .wallet-name{
        width: 50%;
        padding-left: 2rem;
        font-weight: bold;font-size: 1.2rem;
      }
    }
  }
}
.showAllwallet{
  bottom: 0;
  .modallayer{
    display: block;
  }
}
</style>