<template>
  <div class="ranking overflowyauto" ref="rankingdata">
    <div class="ranking-inner">
      <div class="ranking-top">
        <div class="ranking-header">
          <div class="ranking-doubt" @click="isModal = true"></div>
          <div class="ranking-time">
            <div>{{seasonName}}<span v-if="isCollectionTime">(ended)</span> <br/>{{ seasonDate }}</div>
          </div>
        </div>
        <div class="top-three">
          <div class="top-three-bg"></div>
          <div class="top-three-user">
            <div class="three-user medal-two">
              <div class="two-img">
                <img
                  v-if="top3List[1] && top3List[1].image"
                  :src="top3List[1].image"
                  alt=""
                  srcset=""
                  @error="setDefaultImg"
                />
                <img
                  v-else
                  src="@/assets/img/quest/tappop_preview.png"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="two-inner" :class="{ 'caseShow': itemType === 1 && top3List[1]?.has_reward && claimTimeout, 'two-inner-open': top3List[1]?.is_claimed && claimTimeout }">
                <div class="two-name">
                  <span @click="getMySign(top3List[1]? top3List[1] : '', '')"></span>
                  <div class="user-name">{{ top3List[1] ? truncateString(top3List[1].username) : "No data!" }}</div>
                </div>
                <div class="two-points">
                  <span></span>
                  <Tooltip :content="top3List[1] ? top3List[1].score : 0" position="bottom">
                    {{ top3List[1] ? formatNumber(top3List[1].score) : "0" }}
                  </Tooltip>
                </div>
              </div>
              <div class="three-user-bg">
              </div>
            </div>
            <div class="three-user medal-one">
              <div class="two-img">
                <img
                  v-if="top3List[0] && top3List[0].image"
                  :src="top3List[0].image"
                  alt=""
                  srcset=""
                  @error="setDefaultImg"
                />
                <img
                  v-else
                  src="@/assets/img/quest/tappop_preview.png"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="two-inner" :class="{ 'caseShow': itemType === 1 && top3List[0]?.has_reward && claimTimeout, 'two-inner-open': top3List[0]?.is_claimed && claimTimeout }">
                <div class="two-name">
                  <span @click="getMySign(top3List[0]? top3List[0] : '', '')"></span>
                  <div class="user-name">{{ top3List[0] ? truncateString(top3List[0].username) : "No data!" }}</div>
                </div>
                <div class="two-points">
                  <span></span> 
                  <Tooltip :content="top3List[0] ? top3List[0].score : 0" position="bottom">
                    {{ top3List[0] ? formatNumber(top3List[0].score) : "0" }}
                  </Tooltip>
                </div>
              </div>
              <div class="three-user-bg">
              </div>
            </div>
            <div class="three-user medal-three">
              <div class="two-img">
                <img
                  v-if="top3List[2] && top3List[2].image"
                  :src="top3List[2].image"
                  alt=""
                  srcset=""
                  @error="setDefaultImg"
                />
                <img
                  v-else
                  src="@/assets/img/quest/tappop_preview.png"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="two-inner" :class="{ 'caseShow': itemType === 1 && top3List[2]?.has_reward && claimTimeout, 'two-inner-open': top3List[2]?.is_claimed && claimTimeout }">
                <div class="two-name">
                  <span @click="getMySign(top3List[2]? top3List[2] : '', '')"></span>
                  <div class="user-name">{{ top3List[2] ? truncateString(top3List[2].username) : "No data!" }}</div>
                </div>
                <div class="two-points">
                  <span></span>
                  <Tooltip :content="top3List[2] ? top3List[2].score : 0" position="bottom">
                    {{ top3List[2] ? formatNumber(top3List[2].score) : "0" }}
                  </Tooltip>
                </div>
              </div>
              <div class="three-user-bg">
              </div>
            </div>
          </div>
        </div>
        <div class="ranking-nav">
          <div class="ranking-nav-inner">
            <div class="ranking-nav-item">
              <div
                class="nav-item"
                v-for="(item, index) in dailyList"
                :key="index"
                :class="{ active: item.id === itemType }"
                @click="itemTypeFn(item.id)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="ranking-list">
          <ul v-if="itemType === 1">
            <li
              v-for="(item, index) in basicList"
              :key="index"
              :class="{
                youactive:
                  userScoreRank &&
                  userScoreRank.rank > 3 &&
                  item.rank === userScoreRank.rank,
              }"
            >
              <div class="item-img">{{ item.rank }}</div>
              <div class="user-img">
                <img
                  v-if="!item.image"
                  src="@/assets/img/quest/tappop_preview.png"
                  alt=""
                />
                <img v-else :src="item.image" alt="" @error="setDefaultImg"/>
              </div>
              <div class="user-name">{{ truncateString(item.username) }}</div>
              <div class="user-points">
                <Tooltip :content="item.score ? item.score : 0">
                  {{ formatNumber(item.score) }}
                </Tooltip>
              </div>
              <div class="case-img" v-if="item.has_reward && claimTimeout" :class="{ 'case-img-open': item.is_claimed && claimTimeout } "@click="getMySign(item, '')"></div>
            </li>
          </ul>
          <ul v-if="itemType === 2">
            <li
              v-for="(item, index) in friendsList"
              :key="index"
              :class="{ youactive: item.tg_id == getPhone }"
            >
              <div class="item-img">{{ item.rank }}</div>
              <div class="user-img" @click="(item.tg_id)">
                <img
                  v-if="!item.image"
                  src="@/assets/img/quest/tappop_preview.png"
                  alt=""
                />
                <img v-else :src="item.image" alt="" @error="setDefaultImg"/>
              </div>
              <div class="user-name">{{ truncateString(item.username) }}</div>
              <div class="user-points">
                <Tooltip :content="item.score ? item.score : 0">
                  {{ formatNumber(item.score) }}
                </Tooltip>
              </div>
            </li>
          </ul>
            <div v-if="loading" class="data-loading">Loading...</div>
            <div ref="loading" class="data-loading-placeholder"></div>
        </div>
        <div class="ranking-list my-list">
          <ul v-if="itemType === 1 && userScoreRank && userScoreRank.rank !== -1">
            <li>
              <div class="item-img">{{ userScoreRank.rank }}</div>
              <div class="user-img">
                <img
                  v-if="!avatar"
                  src="@/assets/img/quest/tappop_preview.png"
                  alt=""
                />
                <img v-else :src="avatar" alt="" @error="setDefaultImg" />
              </div>
              <div class="user-name">You</div>
              <div class="user-points user-points-you">
                <Tooltip :content="userScoreRank.score ? userScoreRank.score : 0">
                  {{ formatNumber(userScoreRank.score) }}
                </Tooltip>
              </div>
              <div class="case-img" v-if="userScoreRank.has_reward && claimTimeout " :class="{ 'case-img-open': userScoreRank.is_claimed && claimTimeout }" @click="getMySign(userScoreRank, getPhone)"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="modal" :class="{ 'myModalShow': isModal }" @click="isModal = false">
      <div class="modal-overlay"></div>
      <div class="modal-content">
        <div class="modal-body">
          <div class="scrollable-content">
            <ul>
              <li class="rewards-li">
                <div class="rewards-title">{{seasonName}} Rewards</div>
                <img
                  class="rewards-img"
                  src="@/assets/img/quest/rewards.jpg"
                  alt=""
                   @error="setDefaultImg"
                />
              </li>
              <li v-for="(item, index) in ruleDescription" :key="index">
                <div>{{ index + 1 }}.</div>
                <p>{{ item }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <nav-menu on="ranking" :showValue="false"></nav-menu>
    <all-wallet :showWallet="showWallet" type="2" @childClick="updateParentValue" @walletClick="getWallet"></all-wallet>
    <div v-if="getUseraddress && claimTimeout && isLoading">
      <div class="modal-loaing"></div>
      <div class="loading"></div>
      <div class="loading-text">Please do not close the window</div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import NavMenu from "@/components/NavMenu.vue";
import AllWallet from '@/components/AllWallet.vue'
import Web3 from "web3";
import RewardDistributorAbi from "@/abis/RewardDistributorAbi";
import { get, post } from "@/lib/API";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useSwitchNetwork,
  useWeb3ModalProvider,
  useDisconnect,
} from "@web3modal/ethers/vue";
import { BrowserProvider, Contract, formatUnits } from "ethers";
import { getUserAgent } from '@/lib/isMobileDevice'
import { WalletConnectModal } from '@walletconnect/modal'
import axios from 'axios';
import Tooltip from '@/components/Tooltip.vue'

const routeUrl = process.env.ROUTE_URL || "../";
const payuContractAddress = process.env.CONTRACT_ADDRESS;
const getChainId = process.env.CHAIN_ID || '';

export default {
  name: "Ranking",
  components: {
    NavMenu,
    AllWallet,
    Tooltip
  },
  data() {
    return {
      itemType: 1,
      basicList: [],
      friendsList: [],
      myList: [],
      userAddress: localStorage.getItem("userAddress"),
      seasonDate: "",
      userScoreRank: null,
      globalStart: 1,
      globalEnd: 10,
      friendStart: 1,
      friendEnd: 10,
      globalTop3List: [],
      friendTop3List: [],
      globalIsNotData: false,
      friendIsNotData: false,
      showWallet: false,
      modal: '',
      navMenu: '',
      nowSeason: true,
      getPhone: localStorage.getItem("phone") || '',
      seasonName: '',
      seasonId: '',
      isModal: false,
      startTime: '',
      endTime: '',
      ruleDescription: [
        'You must have played at least one game or completed one task to be eligible for the season leaderboard.',
        'The season leaderboard points are calculated as the highest game points of the current season plus the points from invitations and tasks.',
        'At the end of the season, the highest game points will be reset to zero, while the points from invitations and tasks will remain.',
        'Only the global leaderboard has rewards, the friend leaderboard does not.',
        'After the season ends, the points and rankings on leaderboard will not change until the next season begins.',
        'Each season varies in duration, and the rewards may differ.',
        `The season time is calculated in UTC+0, starting at 00:00:00 on the start date and ending at 23:59:59 on the end date.(For example, the season means from 00:00:00 to 23:59:59).`,
        'Season rewards are distributed based on leaderboard rankings.',
      ],
      unwatch: null, 
      getUseraddress: null,
      isClaimReward: false,
      isWallet: true,
      collectionTime: 0,
      isCollectionTime: false,
      claimTimeout: '',
      avatar: '',
      top3List: [],
      seasonsName: '',
      isOKX: false,
      observer: null,
      loading: false,
      isFriend: false,
      seasonStartTime: 0,
      seasonEndTime: 0,
      isReceive: false,
      complianceStatus: false,
      isLoading: false,
      isSecondTransaction: false,
      txHash: localStorage.getItem("txHash"),
      freeGasList: [
        { senderAddress:'MHg2M0Y5REU3ZjA0ZEQ1RkM1ZDY4MkE5ZTJENUNFMUVENzAyZjVCM2U3', privateKey: 'MzhmNDI3ODE2NjhmZjFlYzg4MjYwMmNkM2I3YTNmY2I5YTUxOWI5NzdlYTU4NzMxM2VkMWEzMDk4ZDViODg1Ng==' },
        { senderAddress:'MHg0ODg0MDI1YWQ0YmJlZTY0Q2JEMTdhZDE0YmVhYjBjMjE1ODU4ODMz', privateKey: 'ZTBlNzAwYjM1OGM2ODg1NTk5ZTU0MWJiN2UwN2ZjYTdmZDdmYmM2YjFhMjU3ZWZjMDJjNDFkNjc1MzlhN2RkZg==' },
      ],
      freeGasType: '1',
      myRank: 0,
    };
  },
  computed: {
    // top3List() {
    //   return this.itemType === 1 ? this.globalTop3List : this.friendTop3List;
    // },
  },
  watch: {
    globalTop3List: {
      deep: true,
      handler(newVal, oldVal) {
        if (this.itemType === 1) {
          this.top3List = newVal;
        }
      },
    },
    friendTop3List: {
      deep: true,
      handler(newVal, oldVal) {
        if (!this.itemType) {
          this.top3List = newVal;
        }
      },
    },
    itemType(newValue, oldValue){
      this.top3List = newValue === 1 ? this.globalTop3List : this.friendTop3List;
    }
  },
  setup() {
    const dailyList = [
      { title: "Global", id: 1, active: true },
      { title: "Friends", id: 2, active: false },
    ];
    return {
      dailyList,
    };
  },
  mounted() {
    this.init();
    this.getTgUserData();
    this.disconnectWallet();// Default to disconnect wallet connection first
    this.navMenu = document.querySelector('.navMenu');
    const options = {
      root: this.$refs.rankingdata,
      rootMargin: '0px',
      threshold: 1.0
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.handleScroll();
        }
      });
    }, options);

    this.$nextTick(() => {
      const loadingElement = this.$refs.loading;
      if (loadingElement) {
        this.observer.observe(loadingElement);
      }
    });
  },
  methods: {
    async init() {
      this.getLeaderBoards(true);
      this.getFriendLeaderBoards();
      this.getMyRankScore();
    },
    async getSeasons() {
      const res = await get("/season/get_seasons");
      if (res.code === 0) {
        const isNewSeasonObj = Object.keys(res.data[0]).length;
        const isHistorySeasonObj = Object.keys(res.data[1]).length;

        let newSeasonObj = isNewSeasonObj > 0 ? res.data[0] : null;
        let historySeasonObj = isHistorySeasonObj > 0 ? res.data[1] : null;

        if (isHistorySeasonObj > 0 && isNewSeasonObj === 0) {
          newSeasonObj = res.data[1];
        }

        if (newSeasonObj) {
          this.seasonName = newSeasonObj.name;
          const str = newSeasonObj.start_time.substring(5, 10) + " ~ " + newSeasonObj.end_time.substring(5, 10)
          const search = /-/g;
          const replace = ".";
          const newStr = str.replace(search, replace);
          const newTime = newStr.split(" ~ ");
          this.startTime = newTime[0];
          this.endTime = newTime[1];
          this.seasonDate = newStr;
          this.ruleDescription[6] = `The season time is calculated in UTC+0, starting at 00:00:00 on the start date and ending at 23:59:59 on the end date.(For example, the season ${this.seasonDate} means from ${this.startTime} 00:00:00 to ${this.endTime} 23:59:59).`;
          // Timeout, in seconds
          let targetObj = null;
          if (this.seasonId === newSeasonObj?.id) {
            targetObj = newSeasonObj;
          } else if (this.seasonId === historySeasonObj?.id) {
            targetObj = historySeasonObj;
          }
          if (targetObj) {
            const collectionTime = parseInt(Number(targetObj.gift_expire_days) * 24 * 60 * 60);
            const getEndData = this.processingTime(targetObj.end_time);
            this.seasonStartTime = getEndData;
            this.seasonEndTime = collectionTime + getEndData;
            this.getUserTime(this.seasonEndTime);
            this.seasonsName = targetObj.name;
          }
        }
      }
    },
    async getMySign(item, id) {
      if (this.isReceive) {
        return false;
      }
      this.isReceive = true;
      if (!item || this.itemType === 2) {
        this.isReceive = false;
        return false;
      }
      const tg_id = item.tg_id || id;
      if (item.has_reward && this.claimTimeout && tg_id && this.getPhone) {
        if (tg_id != this.getPhone && !item.is_claimed) {
          this.isReceive = false;
          this.$toast.error("Sorry, it's not your reward!");
          return false;
        }
        if (item.is_claimed) {
          this.isReceive = false;
          const getAddress = this.truncateAddressString(this.getUseraddress);
          this.$toast.error(`The reward has been claimed at ${getAddress}`);
          if(this.txHash) {
            const timer = setTimeout(() => {
              Telegram.WebApp.openLink(`https://explorer.bsquared.network/tx/${this.txHash}`);
              clearTimeout(timer);
            }, 2000);
          }
          return false;
        }
      }
      const res = await get("/season/get_my_rank", {});
      if (res.code === 0) {
        this.userScoreRank = res.data;
        if (this.userScoreRank.has_reward && this.userScoreRank.is_claimed) {
          this.isReceive = false;
          const getAddress = this.truncateAddressString(this.getUseraddress);
          this.$toast.error(`The reward has been claimed at ${getAddress}`);
          if(this.txHash) {
            const timer = setTimeout(() => {
              Telegram.WebApp.openLink(`https://explorer.bsquared.network/tx/${this.txHash}`);
              clearTimeout(timer);
            }, 2000);
          }
          return false;
        } else {
          if (this.complianceStatus) {
            let showTxt = `Do you confirm to claim the rewards for ${this.seasonsName}?`;
            if (this.getUseraddress) {
              showTxt = `Do you confirm claiming the ${this.seasonsName} reward at ${this.truncateAddressString(this.getUseraddress)}?`;
            }
            window.Telegram.WebApp.showConfirm(showTxt, (result) => {
              if (result) {
                this.isLoading = true;
                this.setMySign(item, id);
              } else {
                this.isReceive = false;
                return false;
              }
            });
          } else {
            this.getMyFriendList(item, id, 1);
          }
        }
      }
    },
    async getMyFriendList(item, id, type) {
      try {
          let okFriendsNum = 0;
          let complianceNum = 3;
          let getMyrank = Number(this.myRank);
          if (1 <= getMyrank && getMyrank <= 5) {
            complianceNum = 15;
          }
          if (6 <= getMyrank && getMyrank <= 70) {
            complianceNum = 10;
          }
          if (71 <= getMyrank && getMyrank <= 500) {
            complianceNum = 8;
          }
          if (501 <= getMyrank && getMyrank <= 1900) {
            complianceNum = 6;
          }
          if (1901 <= getMyrank && getMyrank <= 2700) {
            complianceNum = 4;
          }
          const lastFiveFriends = await this.getInvitedFriends(complianceNum, this.seasonsName, type) || [];
          if (lastFiveFriends.length > 0){
            lastFiveFriends.forEach(friend => {
              const InvitationTime = this.processingTime(friend.Friend.CreatedAt);
              if (this.seasonStartTime <= InvitationTime && InvitationTime <= this.seasonEndTime && friend.Friend.inviteTelegramId === this.getPhone){
                okFriendsNum++;
              }
            });
            if (okFriendsNum >= complianceNum) {
              this.complianceStatus = true;
              if (type === 1) {
                this.setMySign(item, id);
              } else {
                this.isLoading = false;
                this.isReceive = false;
              }
            } else {
              this.isLoading = false;
              this.isReceive = false;
              if (type === 1) {
                this.$toast.error(`Invite ${complianceNum} friends(now ${okFriendsNum}/${complianceNum}) to claim your ${this.seasonsName} reward without gas fees`);
              }
              return false;
            }
          }
      } catch (error) {
          console.error(error);
      }
    },
    async getInvitedFriends(complianceNum, seasonsName, type) {
      try {
        const countResponse = await get('/friend/getMyFriendList', { page: 1, pageSize: 20, type: 'friend' });
        if (countResponse.code === 0) {
          const totalCount = countResponse.data.total;
          let lastTenFriends = [];
          if (totalCount === 0 && type === 1) {
            this.$toast.error(`Invite ${complianceNum} people(Now 0/${complianceNum}) to claim your ${seasonsName} reward without gas fees.`);
            this.isReceive = false;
            return false;
          }
          lastTenFriends = countResponse.data.list;
          return lastTenFriends;
        }
      } catch (error) {
        console.error('Failed to fetch friend list:', error);
      }
    },
    async setMySign(item, id) {
      const isMobileDevice = getUserAgent();
      localStorage.setItem("@w3m/recent", '')
      console.log(this.getUseraddress)
      if (this.getUseraddress) {
        this.userClaimReward()
      } else {
        if (isMobileDevice) {
          this.isLoading = false;
          this.showWallet = true;
          this.isReceive = false;
          return false;
        } else {
          try {
            this.getUseraddress = await this.initAccounts();
            if (this.getUseraddress) {
              this.userClaimReward()
            } else {
              this.isReceive = false;
              this.isLoading = false;
            }
          } catch (err) {
            this.isLoading = false;
          //console.log(err);
          }
        }
      }
    },
    rewardList(list) {
      const web3 = new Web3(window.ethereum);
      return list.map((item) => {
        // const weiValue = web3.utils.toWei(item.amount, 'ether')
        return [item.tokenAddress, item.amount];
      });
    },
    itemTypeFn(value) {
      this.itemType = value;
    },
    async initAccounts() {
      // Method 1 for obtaining wallet through browser
      // try {
      //   const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      //   if ( accounts.length > 0 ) {
      //     return accounts[0];
      //   }
      // } catch (err) {
      // }
      // Monitor changes in connection status
      // walletconnect Authorization Wallet Method 2
      // Switching chains
      const { address, chainId, isConnected } = useWeb3ModalAccount();
      
      if (this.unwatch) {
        this.unwatch();
      }

      this.unwatch = this.$watch(
        () => isConnected.value,
        (newIsConnected, oldIsConnected) => {
          // newIsConnected It's a new connection status
          // oldIsConnected It is an old connection state
          if (newIsConnected && address.value) {
            this.$toast.success('Wallet connected successfully.');
            this.isWallet = true;
            this.getUseraddress = address.value;
            try {
              const { switchNetwork } = useSwitchNetwork()
              switchNetwork(Number(getChainId));
            } catch (err) {
             //console.log(err)
            }
            this.userClaimReward();
          } else {
            // console.log('Wallet disconnected');
          }
        }
      );

      if (!address.value || isConnected.value === false || !this.isWallet) {
        const getModal = useWeb3Modal();
        getModal.open();
        return false;
      }
      

      if (isConnected.value && address.value) {
        this.isWallet = true;
        return address.value;
      }
    },
    async userClaimTransaction(
      season,
      receiverInfos,
      userAddress,
      uniqueId,
      sign
    ) {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(
        RewardDistributorAbi,
        payuContractAddress
      );
      try {
        await contract.methods
          .distribute(season, receiverInfos, userAddress, uniqueId, sign)
          .send({ from: userAddress, gasPrice: 1100000 })
          .on("receipt", (receipt) => {
           //console.log("Transaction receipt:", receipt);
            if (receipt.type === "3n") {
              this.userPaid(true, receipt.transactionHash, uniqueId);
            }
          })
          .on("error", (error) => {
            // console.error("Error calling distribute:", error);
            // Get the transaction hash if available
            // const transactionHash = error?.receipt?.transactionHash || null;
            // this.userPaid(false, transactionHash, uniqueId);
          });
      } catch (error) {
        console.error("Error calling distribute:", error);
        // Get the transaction hash if available
        const transactionHash = error?.receipt?.transactionHash || null;
        this.userPaid(false, transactionHash, uniqueId);
        this.isClaimReward = false;
      }
    },
    async gosendTransaction(
      seasonId,
      getRewardList,
      getUseraddress,
      unique_id,
      sign
    ) {
      const that = this;
      const rpcUrl = 'https://mainnet.b2-rpc.com';
      const chainIdNumber = 223;
      const senderAddress = this.freeGasType === '1' ? this.freeGasList[0].senderAddress : this.freeGasList[1].senderAddress;
      const privateKey = this.freeGasType === '1' ? this.freeGasList[0].privateKey : this.freeGasList[1].privateKey;
      const web3 = new Web3(rpcUrl);
      const nonce = await web3.eth.getTransactionCount(atob(senderAddress));
      const contract = new web3.eth.Contract(
        RewardDistributorAbi,
        payuContractAddress
      );
      const gasPrice = 1100000;
      const nonceList = ['nonce too low', 'replacement transaction underpriced', 'nonce already used', 'invalid nonce'];
      console.log('nonce:', web3.utils.toHex(nonce))
      this.isLoading = true;
      try {
        const transaction = {
          nonce: web3.utils.toHex(nonce),
          gasPrice: gasPrice,
          gas: 500000,
          from: getUseraddress,
          to: payuContractAddress,
          chainId: web3.utils.toHex(chainIdNumber),
          data: contract.methods
            .distribute(
              seasonId,
              getRewardList,
              getUseraddress,
              unique_id,
              sign
            )
            .encodeABI(), // Contract functions and parameters
        };
        const signedTx = await web3.eth.accounts.signTransaction(transaction, atob(privateKey));
        //console.log(signedTx)
        const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction)
          .on('transactionHash', function(hash){
            //console.log(hash)
            if (hash) {
              localStorage.setItem("txHash", hash);
              that.txHash = hash;
            }
          })
          .then(function(receipt) {
            //console.log('Transaction receipt:', receipt);
            if(receipt.logs.length > 0) {
              that.userPaid(true, receipt.transactionHash, unique_id);
            }
          })
          .catch(function(error) {
            //console.log('Error001:', error.message);
            if (error.message) {
              if (error.message.indexOf('UniqueId Already Distributed') > -1 || error.message.indexOf('Season To Already Distributed') > -1){
                that.$toast.error('Rewards already claimed!');
                that.userPaid(true, '', unique_id);
                that.claimTimeout = true;
              } else {
                that.restartTransaction(seasonId, getRewardList, getUseraddress, unique_id, sign, 1);
              }
            }
          }); 
      } catch (error) {
        //console.log("Error002:", error);
        let isDataError = 1;
        for (const key in error) {
          if (error.hasOwnProperty(key)) {
            if(error[key]) {
              const message = String(error[key]);
              if(message.indexOf('UniqueId Already Distributed') !== -1 || message.indexOf('Season To Already Distributed') !== -1) {
                that.$toast.error('Rewards already claimed!');
                that.userPaid(true, '', unique_id);
                that.claimTimeout = true;
                isDataError = 2;
              }
            }
          }
        }
        that.restartTransaction(seasonId, getRewardList, getUseraddress, unique_id, sign, isDataError);
      }
    },
    async restartTransaction(seasonId, getRewardList, getUseraddress, unique_id, sign, isDataError) {
      const that = this;
      if (that.isSecondTransaction) {
        that.userPaid(false, '', unique_id);
        that.$toast.error('The network is busy, please try again later');
        this.isClaimReward = false;
        return false;
      }
      const timer = setTimeout(() => {
        if (isDataError === 1){
          that.isSecondTransaction = true;
          that.freeGasType = '2';
          that.gosendTransaction(seasonId, getRewardList, getUseraddress, unique_id, sign);
          clearTimeout(timer);
        }
      }, 2000);
    },
    async getContract(season, receiverInfos, userAddress, uniqueId, sign) {
      const myContractAddress = process.env.CONTRACT_ADDRESS;
      const { walletProvider, walletProviderType } = useWeb3ModalProvider();
      const { address, chainId, isConnected } = useWeb3ModalAccount();
      if (!address.value || isConnected.value === false) {
        const getModal = useWeb3Modal();
        getModal.open();
      }
      if (isConnected.value && address.value && walletProvider.value) {
        const providera = new BrowserProvider(walletProvider.value);
        const signer = await providera.getSigner();
        const contract = new Contract(
          myContractAddress,
          RewardDistributorAbi,
          signer
        );
        try {
          const overrides = {
            gasPrice: this.isOKX ? null : 1000000 // 0.001 gwei
          };
          const tx = await contract.distribute(
            season,
            receiverInfos,
            address.value,
            uniqueId,
            sign,
            overrides
          );
         ////console.log("Transaction hash:", tx.hash);
          const receipt = await tx.wait();
          //console.log("Transaction receipt:-------", receipt, receipt.hash);
          if (receipt.hash && receipt.status === 1) {
            this.userPaid(true, receipt.hash, uniqueId);
          } else {
            this.userPaid(false, '', uniqueId);
            this.isClaimReward = false;
          }
        } catch (error) {
          this.userPaid(false, '', uniqueId);
          //console.log("Error calling distribute:", error);
          const errorMessage = error.message;
          const regex = /reason="([^"]+)"/;
          const match = errorMessage.match(regex);
          const specificErrorMessage = match ? match[1] : null;
          //console.log("specificErrorMessage: -----------------", specificErrorMessage);
          if (specificErrorMessage) {
            if (specificErrorMessage.indexOf("UniqueId Already Distributed") > -1 || specificErrorMessage.indexOf("Season To Already Distributed") > -1) {
              this.userPaid(true, '', uniqueId);
            } else {
              this.isClaimReward = false;
              this.$toast.error(specificErrorMessage);
            }
          } else {
            this.isClaimReward = false;
            this.$toast.error('Transaction Failed.');
          }
        }
      } else {
        // console.log("Not connected");
        this.userPaid(false, '', uniqueId);
        this.isClaimReward = false;
        const getModal = useWeb3Modal();
        getModal.open();
      }
    },
    async getMyRankScore() {
      const res = await get("/season/get_my_rank", {});
      if (res.code === 0) {
        this.userScoreRank = res.data;
        this.seasonId = this.userScoreRank.latest_season_id;
        this.getSeasons();
        this.getMySeasonDistribution(this.seasonId);
      }
    },
    truncateAddressString(str) {
      const firstPart = str.substring(0, 5);
      const lastPart = str.substring(str.length - 4);
      return `${firstPart}....${lastPart}`;
    },
    async userPaid(isSuccess, hash, uniqueId) {
      const res = await post("/distribution/userPaid", {
        is_success: isSuccess,
        tx_hash: hash,
        unique_id: uniqueId,
      });
      if (res.code === 0) {
        this.isLoading = false;
        this.isSecondTransaction = false;
        this.freeGasType = '1';
        const getAddress = this.truncateAddressString(this.getUseraddress);
        if (hash) {
          this.init();
          this.$toast.success(`The reward has been claimed at ${getAddress}!`);
        }
       //console.log("userPaid success", res.data);
      }
    },
    async getLeaderBoards(isInit) {
      // if (this.globalIsNotData) return;
      
      this.loading = true;
      const res = await post("/season/get_leaderboards", {
        start: this.globalStart,
        end: this.globalEnd,
      });
      if (res.code === 0) {
        if (!res.data.lb || res.data.lb.length < 10)
          this.globalIsNotData = true;
        this.basicList = isInit ? res.data.lb : [...this.basicList, ...res.data.lb];
        this.globalTop3List = res.data.top3 || [];
        if(this.globalTop3List.length > 0){
          this.globalTop3List.map((item, index) => {
            if (item.has_reward) {
              this.isCollectionTime = true;
            }
          })
        } else {
          
        }
        this.loading = false;
      }
    },
    async getFriendLeaderBoards() {
      if (this.friendIsNotData) return;
      this.loading = true;
      const res = await post("/season/get_friend_leaderboards", {
        start: this.friendStart,
        end: this.friendEnd,
      });
      if (res.code === 0) {
        if (!res.data.friend_lb || res.data.friend_lb.length < 10)
          this.friendIsNotData = true;
        this.friendsList = [...this.friendsList, ...res.data.friend_lb];
        this.friendTop3List = res.data.top3 || [];
        this.loading = false;
      }
    },
    handleScroll() {
      if (this.loading) return;
      if (this.itemType === 1 && !this.globalIsNotData) {
        this.globalStart = this.globalStart + 10;
        this.globalEnd = this.globalEnd + 10;
        if (this.globalStart > 90) {
          this.globalEnd = 97;
        }
        this.getLeaderBoards();
      } else if (this.itemType === 2 && !this.friendIsNotData) {
        this.friendStart = this.friendStart + 10;
        this.friendEnd = this.friendEnd + 10;
        if (this.friendStart > 90) {
          this.friendEnd = 97;
        }
        this.getFriendLeaderBoards();
      }
    },
    updateParentValue(value) {
      this.showWallet = value;
    },
    getWallet(value) {
      // console.log('getWallet', value);
    },
    setDefaultImg(event){
      event.target.src = routeUrl + 'assets/img/quest/tappop_preview.png';
    },
    async disconnectWallet() {
      const { disconnect } = useDisconnect();
      const { isConnected } = useWeb3ModalAccount();
      if (isConnected.value) {
        try {
          await disconnect();
          // console.log('Wallet disconnected manually');
        } catch (error) {
         //console.log('Error disconnecting wallet:', error);
        }
      }
    },
    async userClaimReward() {
      const that = this;
      if (this.isClaimReward) {
        this.isLoading = false;
        return false;
      }
      this.isReceive = false;
      // Prevent duplicate clicks
      this.isClaimReward = true;
      post("/distribution/claimReward", {
        seasonId: this.seasonId,
        userAddress: this.getUseraddress,
      })
        .then((response) => {
          if (response.code === 0) {
            const { seasonId, reward_list, unique_id, sign } =
              response.data;
            if (reward_list && reward_list.length > 0) {
              const getRewardList = that.rewardList(reward_list);
              if (
                seasonId &&
                getRewardList.length > 0 &&
                this.getUseraddress &&
                unique_id &&
                sign
              ) {
                // Payment Method 1
                // that.userClaimTransaction(seasonId, getRewardList, this.getUseraddress, unique_id, sign);
                // Payment Method 2
                that.gosendTransaction(seasonId, getRewardList, this.getUseraddress, unique_id, sign);
                // Payment methods for walletConnect
                // let getModal = localStorage.getItem("@w3m/recent") ? JSON.parse(localStorage.getItem("@w3m/recent")) : [];
                // if (getModal.length > 0) {
                //   if(getModal[0].name.indexOf("OKX") > -1 || getModal[0].name.indexOf("okx") > -1){
                //     this.isOKX = true;
                //   } else {
                //     this.isOKX = false;
                //   }
                //   this.getContract(
                //     seasonId,
                //     getRewardList,
                //     this.getUseraddress,
                //     unique_id,
                //     sign
                //   );
                // } else {
                //   this.isOKX = false;
                //   this.getContract(
                //     seasonId,
                //     getRewardList,
                //     this.getUseraddress,
                //     unique_id,
                //     sign
                //   );
                // }
              } else {
                this.isClaimReward = false;
                this.userPaid(false, '', unique_id);
                this.$toast.error('Transaction Failed.');
              }
            } else {
              this.userPaid(false, '', unique_id);
              this.isClaimReward = false;
            }
          } else {
            this.isLoading = false;
            this.isClaimReward = false;
            if (response.msg === 'UserDidNotParticipateInThisSeason') {
              this.$toast.error('User did not participate in this season.');
            } else {
              this.$toast.error('Transaction Failed.');
            }
          }
        })
        .catch((error) => {
         //console.log(error);
        });
    },
    truncateString(str) {
      if (str.length <= 9) {
        return str;
      }
      const firstPart = str.substring(0, 3);
      const lastPart = str.substring(str.length - 3);
      return `${firstPart}...${lastPart}`;
    },
    async getUserTime(seasonEndTime) {
      try {
        const response = await axios.get('https://worldtimeapi.org/api/ip');
        const getTimeT0 = response.data.unixtime ? this.processingTime(response.data.utc_datetime) : this.processingTime();
        if (getTimeT0 > seasonEndTime) {
          //Overdue collection
          this.claimTimeout = false;
         //console.log('Overdue collection');
        } else {
          //Not exceeding the time limit for collection
          this.claimTimeout = true;
          this.getMyFriendList();
         //console.log('Not exceeding the time limit for collection');
        }
      } catch (error) {
        console.error(error);
      }
    },
    processingTime(time) {
      if ( time ) {
        const timestamp = Date.parse(time) / 1000;
        return parseInt(timestamp);
      } else {
        const date = new Date();
        const utcTimestamp = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
        return parseInt(utcTimestamp/1000);
      }
    },
    async getTgUserData() {
      const res = await get("/tguser/myTelegramUser");
      if (res.code === 0) {
        const user = res.data.user;
        this.getPhone = user.telegram_id;
        this.avatar = user.avatar;
        this.getUseraddress = user.wallet_address;
      }
    },
    formatNumber(value) {
      if (value >= 100000) {
        const roundedValue = Math.round(value / 1000);
        return `${roundedValue}k`;
      } else {
        return value;
      }
    },
    async getMySeasonDistribution(seasonId){
      const res = await post("/distribution/getMySeasonDistribution", {
        seasonId: seasonId,
      });
      if (res.code === 0) {
        if(res.data.data?.rank) {
          this.myRank = res.data.data.rank;
        }
      }
    }
  },
  beforeDestroy() {
    if (this.unwatch) {
      this.unwatch();
    }
  }
};
</script>
<style lang="scss" scoped>
.ranking-test{
  position: fixed;
  top: 4.5rem;
  left: 1rem;
  width: 2rem;
  height: 2rem;
  z-index: 9999;
}
.ranking-inner {
  padding:0 .5rem 9rem .5rem;
}
.ranking-top{
  margin-top: 0.9rem;
  border-radius: 1.5rem;
  border: .35rem solid #024CCE;
  width: 100%;
  background: url("@/assets/img/ranking/coin.png") no-repeat 2% .6rem/2.5rem,
  url("@/assets/img/ranking/coin.png") no-repeat 98% .6rem/2.5rem;
  background-color: #FEF8EE;
}
.ranking-header {
  text-align: center;
  position: relative;
  top: -1.55rem;
  background:  url("@/assets/img/ranking/ranking-bg.png") no-repeat
    top center/20rem;
  height: 5.5rem;
  padding-top: 1.2rem;
  width: 20rem;
  border-radius: 0 0 1.5rem 1.5rem;
  margin: 0 auto;
  margin-bottom: -3.2rem;
  .ranking-doubt {
    position: absolute;
    top: 1rem;
    right: 17%;
    width: 1.3rem;
    height: 1.3rem;
    background: url("@/assets/img/icons/doubt.png") no-repeat center
      center/cover;
      z-index: 9;
  }
  .ranking-time {
    position: relative;
    left: 0;
    z-index: 2;
    padding-top: .5rem;
    font-size: 1.3rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    text-align: center;
    div {
      padding: 0 1rem;
      font-size: 1rem;width: 75%;
    }
  }
}
.top-three {
  position: relative;
  .top-three-bg {
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("@/assets/img/icons/light.png") no-repeat center center/90%;
  }
  .top-three-user {
    display: flex;
    height: 100%;
    // padding: 0 1rem 1rem 1rem;
    .three-user {
      width: 10rem;
      height: 100%;
      padding-top: .7rem;
      position: relative;
      height: 18rem;
      .three-user-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("@/assets/img/ranking/ranking-two.png") no-repeat center
          center/8rem;
          background-position-y: 47%;
        div {
          position: absolute;
          top: 11.6rem;
          left: 50%;
          transform: translate(-50%, -50%);
          font-weight: 900;
          line-height: 1.9rem;
          font-style: italic;
          width: 1.9rem;
          height: 1.9rem;
          background: var(
            --unnamed,
            linear-gradient(92deg, #FCAF01 0, #072593 50.15%)
          );
          border-radius: 50%;
          text-align: center;
          font-size: 1rem;
          text-indent: -0.2rem;
        }
      }
      .two-img {
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 50%;
        margin: 0 auto;
        margin-top: 3.5rem;
        margin-bottom: 2.5rem;
        color: #161616;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .two-inner {
        position: relative;
        left: 0;
        z-index: 9;
        padding-top: 1.6rem;
        text-align: center;
        font-weight: bold;
        .two-points {
          font-size: 0.6rem;
          color: #161616;
          span{
            display: inline-block;
            width: 1rem;height: 1rem;
           background: url("@/assets/img/icons/points@1x.png") no-repeat center
          center/100%;margin-right: .2rem;
            position: relative;
            top: 0.2rem;
          }
        }
        .two-name{
          font-size: .8rem;
          height: 1.5rem;
          color: #161616;line-height: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: .5rem;
          .user-name{
            height:2.2rem;
            max-width: 70%;
            overflow: hidden;
            white-space: nowrap; 
            text-overflow: ellipsis;
          }
          span{
            width: 1.5rem;height: 1.5rem;
            background: url("@/assets/img/icons/treasure-chest-shut.svg") no-repeat center
            center/100%;
            display: none;margin-right: .3rem;
          }
        }
      }
      .caseShow{
        margin-left: -1rem;
        .two-name{
          span{
            display: inline-block;
           background: url("@/assets/img/icons/treasure-chest-shut.svg") no-repeat center
          center/100%;
          }
        }
      }
      .two-inner-open {
        .two-name{
          span{
            display: inline-block;
            width: 1.5rem;height: 1.5rem;
           background: url("@/assets/img/icons/treasure-chest.png") no-repeat center
          center/100%;
          }
        }
      }
    }
    .medal-two {
      position: relative;
      .two-inner{
        padding-top: 2.3rem;
      }
    }
    .medal-three {
      position: relative;
      .two-img{
        margin-top: 4.5rem;
      }
      .three-user-bg {
        background-image: url("@/assets/img/ranking/ranking-three.png");background-position-y: 53%;
      }
      .two-inner{
        padding-top: 1.3rem;
      }
    }
    .medal-one {
      width: 42%;
      position: relative;
      z-index: 3;
      padding-top: 1.68rem;
      margin-top: 1rem;
      height: 14rem;
      .three-user-bg {
        background-image: url("@/assets/img/ranking/ranking-one.png");
        div {
          top: 12.8rem;
        }
      }
      .two-img {
        width: 5rem;
        height: 5rem;
        margin-top: 0;margin-bottom: 4.5rem;
      }
      .two-inner {
        padding-left: 0;
      }
    }
  }
}
.ranking-nav {
  padding-right: .8rem;
  display: flex;
  justify-content: flex-end;
  .ranking-nav-inner {
    width: 12rem;
    color: #FCAF01;
    font-size: 1.2rem;
    font-weight: bold;
  }
  .ranking-nav-item {
    display: flex;
    justify-content: center;
    font-size: 0.9rem;
    text-align: center;
    .nav-item {
      background: #FFF4D5;
      padding: 0.2rem 1rem;
      height: 1.8rem;
      line-height: 1.5rem;
      border: 1px #DDC294 solid;
      &:nth-child(1) {
        -webkit-clip-path: polygon(0 0, 100% 0%, 73% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0%, 73% 100%, 0 100%);
        position: relative;
        right: -1.2rem;
        padding-right: 2rem;
      }
      &:nth-child(2) {
        padding-left: 2rem;
        -webkit-clip-path: polygon(18% 0, 100% 0%, 100% 100%, 0 100%);
        clip-path: polygon(18% 0, 100% 0%, 100% 100%, 0 100%);
      }
    }
    .active {
      background: #FCAF01;
      color: #fff;
      height: 2.4rem;
      position: relative;
      top: -0.6rem;
      line-height: 2.3rem;
      &:nth-child(2) {
        left: -0.2rem;
        -webkit-clip-path: polygon(35% 0, 100% 0%, 100% 100%, 0 100%);
        clip-path: polygon(35% 0, 100% 0%, 100% 100%, 0 100%);
      }
    }
  }
}
.ranking-list {
  ul {
    padding: 0 .5rem;
    li {
      text-align: center;
      background: #FFF4D5;
      padding: 0.4rem 0;
      margin-bottom: 1rem;
      padding-left: 0.5rem;
      font-size: 0.8rem;
      border: 1px #DDC294 solid;
      border-radius: .5rem;
      box-shadow: 0px 4px 10px 0px rgba(147, 106, 13, 0.4);
      // overflow: hidden;
      &:hover {
        background: #FCAF01;
        .item-img {
          color: #FCAF01;
        }
      }
      .item-img {
        color: #fff;
        width: 2.6rem;
        height: 2.6rem;
        text-align: center;
        line-height: 2.5rem;
        border-radius: 50%;
        font-size: 1.4rem;
        font-weight: bold;
        background: url("@/assets/img/icons/rank-img.png") no-repeat center center/100%;
        color: #FFF4D5;
        -webkit-text-stroke: 1px black;
        -webkit-text-fill-color: #FFF4D5;
      }
      .user-name {
        width: 35%;font-size: 1rem;font-weight: bold;color: #161616;
        overflow: hidden;
        white-space: nowrap; 
        text-overflow: ellipsis;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #161616;
      .user-img {
        width: 2.6rem;
        height: 2.6rem;
        border-radius: 50%;
        border: 0.15rem solid #064093;
        background: #fff;
        // background: #fff url("@/assets/img/icons/user-img.svg") no-repeat center
        //   center/100% 100%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .case-img {
        width:  3rem;
        height: 2.3rem;
        margin-right: 0.5rem;
        border-radius: 1.5rem 1.5rem 0 0;
        background: url("@/assets/img/icons/treasure-chest-shut.png") no-repeat center
          center/2rem;
      }
      .case-img-no{
        display: none;
      }
      .case-img-open{
        background:url("@/assets/img/icons/treasure-chest.png") no-repeat center
          center/55%;
      }
      .user-points{
        height: 100%;
        padding-left: 2rem;line-height: 1.9rem;
        font-size: 1rem;font-weight: bold;
        margin-right: .5rem;
        background: url("@/assets/img/friends/points.png") no-repeat left
          center/1.9rem;
      }
      .user-points-you{
        padding-right: .5rem;
      }
    }
    .youactive {
      background: #FCAF01;
      color: #fff;
      .item-img {
        color: #4d3af6;
      }
    }
  }
}
.my-list {
  width: 100%;
  position: absolute;
  bottom: 5rem;
  left: 0;z-index: 9;
  ul {
    li {
      background: #FCAF01;
      color: #fff;
      .item-img {
        color: #FCAF01;
      }
      margin-bottom: 0;
      .user-name {
        width: 20%;
      }
    }
  }
}

.modal-body{
  padding: 1.5rem 1rem 2rem 1rem;
}

.scrollable-content {
  ul{
    li{
      display: flex;
      font-size: 0.8rem;
      div{
        padding-right: .4rem;
      }
      .rewards-title{
        font-weight: bold;font-size: .8rem;color: #161616;
        line-height: 1.5rem;
      }
      .rewards-img{
        width: 100%;height: auto;
        margin-bottom: .5rem;
      }
    }
    .rewards-li{
      display: block;
    }
  }
}
.data-loading{
  text-align: center;
  font-size: 1rem;
  color: #a2a2a2;
  height: 2rem;
}
.modal-loaing{
  width: 100vw;height: 100vh;
  background: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;left: 0;
  z-index: 99;
}
.loading{
  position: fixed;
  top:46%;left:46%;
  transform: translate(-50%, -50%);
  width: 3rem;height: 3rem;
  z-index: 999;
  background: url('@/assets/img/icons/loading.png') no-repeat center center/contain;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-text{
  position: fixed;
  top: 46%;
  left: 46%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 100%;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  margin-top: 6rem;
}
</style>
