import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import './assets/style.scss';

const globalVars = reactive({
  myGlobalVar: 1
})

const app = createApp(App)
app.provide('globalVars', globalVars)

app.use(store).use(router).use(VueToast, {
  position: 'top'
}).mount('#app')