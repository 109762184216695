<template>
  <div class="qrcode-modal" :class="{ 'active': showModal }">
    <div class="modal-overlay" v-show="showModal" @click="showModal = false"></div>
    <div class="modal-content">
      <div class="title">WalletConnect
        <div class="friends-refresh friends-copy" @click="copyText()"></div>
      </div>
      <canvas ref="qrcodeCanvas"></canvas>
      <div ref="canvasContainer"></div>
      <div class="logo-img"><img :src="logoSrc"/></div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'

const routeUrl = process.env.ROUTE_URL || "../";
const qrcodeUrl = process.env.NEXT_PUBLIC_DOMAIN || "";

export default {
  data() {
    return {
      showModal: false,
      token: localStorage.getItem('token') || '',
      qrcodeData: qrcodeUrl +'?token=',
      qrcodeOptions: {
        errorCorrectionLevel: 'H',
        scale: 3,
        colorDark: '#000000',
        colorLight: '#ffffff',
        margin: 1,
        quality: 0.3,
      },
      logoSrc: routeUrl + 'assets/img/icons/WalletConnect.webp',
      type: '1',
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setCanvasSize)
  },
  mounted() {
  },
  methods: {
    setCanvasSize() {
      const canvas = this.$refs.qrcodeCanvas
      const width = window.innerWidth - 50
      const height = width 
      canvas.width = width
      canvas.height = height
      this.generateQRCode(width, height)
    },
    show(type) {
      this.type = type
      this.showModal = true
      window.addEventListener('resize', this.setCanvasSize)
      this.setCanvasSize()
    },
    generateQRCode(width, height) {
      const showData = this.qrcodeData + this.token + '&type=' + this.type
      const canvas = this.$refs.qrcodeCanvas
      this.qrcodeOptions.width = width
      this.qrcodeOptions.height = height
      QRCode.toCanvas(canvas, showData, this.qrcodeOptions, (error) => {
        if (error) {
          console.error(error)
        }
      })
    },
    hide() {
      this.showModal = false
    },
    copyText() {
      const txt = this.qrcodeData + this.token + '&type=' + this.type;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(txt)
          .then(() => {
            console.error('Successfully!');
            this.$toast.success('Please open the copied link in the wallet browser');
          })
          .catch((error) => {
            console.error('Copying failed:', error);
            this.copyFallback(txt);
          });
      } else {
        this.copyFallback(txt);
      }
    },
    copyFallback(txt) {
      const textarea = document.createElement('textarea');
      textarea.value = txt;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.$toast.success('Please open the copied link in the wallet browser');
    }
  }
}
</script>

<style lang="scss" scoped>
canvas{
  border-radius: .5rem;
  width: 5rem;height: 5rem;
}
.qrcode-modal {
  position: fixed;
  top: 70rem;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  height: 100vh;
  transition: all 0.3s ease-in-out;
}
.active{
  top: 0;
}
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: relative;
  width: 100%;background: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
  .title{
    height: 3rem;font-size: 1.4rem;
    line-height: 3rem;
    text-align: left;
    background: url('@/assets/img/icons/WalletConnect.webp') no-repeat left center/ 3rem 3rem;
    padding-left: 4rem;
  }
  .friends-refresh{
    background:#fff url('@/assets/img/icons/copy.svg') no-repeat center center / 80%;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    border-radius: 50%;
    float: right;
  }
}

.logo-img {
  width: 3rem;height: 3rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: .5rem;
  img{
    width: 100%;height: 100%;
  }
}
</style>