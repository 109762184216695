<template>
  <router-view/>
</template>
<script setup>
  import { ref } from 'vue';
  import { createWeb3Modal, defaultConfig} from '@web3modal/ethers/vue'

  // 1. Get projectId from https://cloud.walletconnect.com
  const projectId = process.env.PROJECT_ID || ''
  const webappurl = process.env.WEB_APP_URL || ''
  // 2. Set chains

  const mainnet = [
    {
      "chainId": 223,
      "name": 'B2 Mainnet',
      "currency": 'BTC',
      "explorerUrl": 'https://explorer.bsquared.network',
      "rpcUrl": 'https://mainnet.b2-rpc.com'
    },
    {
      "chainId": 1,
      "name": "Ethereum Mainnet",
      "currency": "ETH",
      "explorerUrl": "https://etherscan.io",
      "rpcUrl": "https://eth.llamarpc.com"
    },
    {
      "chainId": 56,
      "name": "Binance Smart Chain Mainnet",
      "currency": "BNB",
      "explorerUrl": "https://bscscan.com",
      "rpcUrl": "https://bsc-dataseed.binance.org/"
    },
    {
      "chainId": 42161,
      "name": "Arbitrum One",
      "currency": "ETH",
      "explorerUrl": "https://arbiscan.io",
      "rpcUrl": "https://arb1.arbitrum.io/rpc"
    },
    {
      "chainId": 8453,
      "name": "Base Mainnet",
      "currency": "ETH",
      "explorerUrl": "https://basescan.org",
      "rpcUrl": "https://mainnet.base.org"
    },
    {
      "chainId": 137,
      "name": "Polygon Mainnet",
      "currency": "MATIC",
      "explorerUrl": "https://polygonscan.com",
      "rpcUrl": "https://polygon-rpc.com/"
    },
    {
      "chainId": 43114,
      "name": "Avalanche C-Chain",
      "currency": "AVAX",
      "explorerUrl": "https://snowtrace.io",
      "rpcUrl": "https://api.avax.network/ext/bc/C/rpc"
    },
    {
      "chainId": 10,
      "name": "Optimism",
      "currency": "ETH",
      "explorerUrl": "https://optimistic.etherscan.io",
      "rpcUrl": "https://mainnet.optimism.io"
    },
    {
      "chainId": 324,
      "name": "zkSync Era",
      "currency": "ETH",
      "explorerUrl": "https://zksync.io",
      "rpcUrl": "https://mainnet.era.zksync.io"
    },
    {
      "chainId": 59144,
      "name": "Linea Mainnet",
      "currency": "ETH",
      "explorerUrl": "https://explorer.linea.build",
      "rpcUrl": "https://rpc.linea.build"
    }
  ]

  // 3. Create your application's metadata object
  const metadata = {
    name: 'TapPop',
    description: '',
    url: webappurl, // url must match your domain & subdomain
    icons: ['https://s3.triplec.cc/console-admin/1719371538photo_2024-06-20_17-22-48.jpg']
  }

  // 4. Create Ethers config
  const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: false, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 1, // used for the Coinbase SDK
  })

  // 5. Create a Web3Modal instance
  createWeb3Modal({
    ethersConfig,
    chains: mainnet,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    enableOnramp: true, // Optional - false as default
    featuredWalletIds: [
      'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
      'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b',
      '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
      '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
      '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',
      '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
    ]
  })

</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}
ul{padding: 0; margin: 0;}
li{list-style: none;}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #fff;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
