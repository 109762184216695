<template>
  <div class="home">
    <div class="page startPage">
      <div class="logo" id="logo"></div>
      <div class="btn start-btn image-animation" @click="startGame"></div>
      <div class="lamp" @click=" typeDataFn(1)"></div>
      <div class="game-tips" @click="typeDataFn(2)"></div>
      <div class="come-join" v-if="showJoin">
        <div class="come-join-inner">
          <div class="join-over" @click="showJoin = false"></div>
          <div class="join-list join-title"></div>
          <div class="join-list join-chanel" @click="joinFn('chanel')"></div>
          <div class="join-list join-group" @click="joinFn('chatgroup')"></div>
          <div class="join-list join-here"  @click="joinFn('playhere')"></div>
          <div class="join-list join-nifis"></div>
        </div>
      </div>
    </div>
    <div class="page restartPage" style="display: none">
      <div class="final-score">
        <div class="hint"></div>
        <div class="score"></div>
        <div
          class="wrapper"
          style="position: absolute; top: 0; text-align: center; width: 100%"
        ></div>
      </div>
      <div class="highestscore">All-Time High Game Points: {{ AllHeightScore }}</div>
      <div class="btn restart-btn image-animation" @click="restartGame"></div>
      <div class="game-share" :class="{ 'image-animation': isAnimating }" @click="goTelegramBot()"></div>
      <div id="divToCapture">
        <div class="final-score">
          <div class="new-score">{{ myScore }}</div>
          <div
            class="wrapper"
            style="position: absolute; top: 0; text-align: center; width: 100%"
          ></div>
        </div>
        <div class="highestscore">All-Time High Game Points: {{ AllHeightScore }}</div>
      </div>
    </div>
    <div class="MyToast disappear"></div>
    <div class="maximumscore maximumscore-hide">
      <div class="music-switch" :class="{ 'music-switch-off': musicSwitch === false || musicSwitch === 'false' }" @click="musicSwitchFn()"></div>
      <div class="maximumscore-inner">
        <div class="hint">Your Highest GamePoints This Season</div>
        <div class="myscore">{{ myRankHeightScore }}</div>
      </div>
    </div>
    <nav-menu
      on="game"
      :showValue="isShowNavMenu"
      @childClick="updateParentValue"
    ></nav-menu>
    <div class="nav blindBox">
      <ul>
        <li class="goblindbox">开盲盒</li>
      </ul>
    </div>
    <div class="modal" :class="{ 'myModalShow': isModal }" @click="isModal = false">
      <div class="modal-overlay"></div>
      <div class="modal-content" v-if="listData.length > 0">
        <div class="modal-body">
          <div class="scrollable-content">
            <ul class="modal-ul">
              <li v-for="(item, index) in listData" :key="index">
                <div v-if="typeData === 2 && item.type === 'text'">
                  <div class="modal-title">{{ item.title }} <span>{{ item.des }}</span></div>
                  <ul v-if="item.childs.length > 0">
                    <li class="childs-li" v-for="(child, index) in item.childs" :key="index">
                      <div class="drop">• </div>
                      <div class="modal-title">{{ child.title }} <span>{{ child.des }}</span></div>
                    </li>
                  </ul>
                </div>
                <div class="modal-table" v-if="typeData === 2 && item.type === 'table'">
                  <table>
                    <tr>
                      <th v-for=" thtitle in item.thtitle" :key="index">{{thtitle}}</th>
                    </tr>
                    <tr v-for="(row, index) in item.list" :key="index">
                      <td>{{ row.content1 }}</td>
                      <td>{{ row.content2 }}</td>
                    </tr>
                  </table>
                </div>
                <div class="typeli" v-if="typeData === 1">
                  <div>{{ index + 1 }}.</div>
                  <p>{{ item }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { init } from "../game/index.js";
import Game from "../game/game.js";
import NavMenu from "@/components/NavMenu.vue";
import axios from "axios";
import CryptoJS from "crypto-js";
import { get, post } from "@/lib/API";
import html2canvas from 'html2canvas';
import FormData from 'form-data';
import { S3Client, GetObjectCommand, PutObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';


function parseInitData(initData) {
  return Object.fromEntries(new URLSearchParams(initData).entries());
}

function generateDataCheckString(data) {
  const sortedKeys = Object.keys(data)
    .filter((key) => key !== "hash")
    .sort();
  return sortedKeys.map((key) => `${key}=${data[key]}`).join("\n");
}

function generateSecretKey(botToken) {
  return CryptoJS.HmacSHA256(botToken, "WebAppData").toString(CryptoJS.enc.Hex);
}

function verifyData(initData, botToken) {
  const data = parseInitData(initData);
  const dataCheckString = generateDataCheckString(data);
  const secretKey = generateSecretKey(botToken);
  const calculatedHash = CryptoJS.HmacSHA256(
    dataCheckString,
    secretKey
  ).toString(CryptoJS.enc.Hex);
 //console.log(dataCheckString, secretKey, calculatedHash, data.hash);
  return calculatedHash === data.hash;
}

const accessKeyId = process.env.ACCESS_KEY_ID || '';
const secretAccessKey = process.env.SECRET_ACCESS_KEY || '';
const region = process.env.REGION || '';
const endpoint = process.env.ENDPOINT || '';
const bucketName = process.env.BUCKETNAME || '';

export default {
  name: "Home",
  components: {
    NavMenu,
  },
  data() {
    return {
      initData: Telegram.WebApp.initData || '',
      initDataUnsafe: Telegram.WebApp.initDataUnsafe || '',
      isVerified: false,
      userAddress: localStorage.getItem("userAddress"),
      session_id: null,
      season_id: null,
      myRankHeightScore: 0,
      AllHeightScore: 0,
      isModal: false,
      airdropList: [
        { type: 'text',title: 'Token Distribution', des: '', childs: [] },
        { type: 'text',title: 'Total Supply:', des: 'One billion (1,000,000,000). ', childs: [] },
        { type: 'text',title: 'Token Distribution:', des: 'Airdrops will be conducted in phase II before TGE. ', childs: [] },
        { type: 'text',title: 'Public Chain: ', des: 'TBC', childs: [] },
        { type: 'table', thtitle: ['Allocation', 'Share'], list: [
          { content1: 'Financing', content2: '15%', },
          { content1: 'TapPop Foundation', content2: '5%', },
          { content1: 'Partnership & Incentives', content2: '10%', },
          { content1: 'Airdrop', content2: '70%', },
          ], childs: [] 
        },
        { type: 'text',title: 'Roadmap', des: '', childs: [ { title: '', des: "TapPop's popping phases in the next 6 months!" }] },
        { type: 'text',title: 'Phase 1', des: '', childs: [ 
          { title: 'Development Completion:', des: " Complete the design and development of the initial game architecture to optimize performance on the Ton ecosystem." },
          { title: 'Game Launch:', des: " TapPop game and community bot go live on Telegram." },
          { title: 'Deployment:', des: " Deploy on the Ton ecosystem." },
        ] },
        { type: 'text',title: 'Phase 2', des: '', childs: [ 
          { title: 'User Acquisition Strategy:', des: " Marketing activities and partnerships to attract more users." },
          { title: 'Engagement Plan:', des: "Launch user engagement plans such as leaderboards, referral programs, and daily interaction tasks to maintain user activity." },
          { title: 'TGE:', des: " Initiate the token TGE." },
          { title: 'Airdrop:', des: "Conduct token airdrops to early participating users as incentives." },
        ] },
      ],
      typeData: 1,
      gameList: [
        'You must have played at least one game or completed one task to be eligible for the season leaderboard.',
        'The game features a season concept, where game points can only be earned during an active season.',
        'Successfully jumping to the next box awards 10 points, while landing in the exact center of the box grants an additional bonus of 20 points.',
        'Once the season starts, the highest game points earned in that season will be recorded as the game points.',
        'When the season ends, the game points are reset to zero, and no game points are recorded until the next season begins.',
        'Points obtained from invitations or tasks will not be reset at the end of the season.',
        'The leaderboard points are the sum of game points and points earned from invitations or tasks.',
      ],
      listData: [],
      musicSwitch: localStorage.getItem("musicSwitch") || "true",
      fixedText: process.env.TG_LINK || '',
      isAnimating: true,
      myScore: 0,
      showJoin: false,
      showJoinTime: localStorage.getItem("showJoinTime") || "",
      showJoinNum: localStorage.getItem("showJoinNum") || 0,
      dialogCount: 0
    };
  },
  setup() {
    const isShowNavMenu = ref(false);
    const showToast = (value) => {
      isShowNavMenu.value = value;
    };

    return {
      isShowNavMenu,
      showToast,
    };
  },
  mounted() {
    const getToken = localStorage.getItem("token");
    const platform = Telegram.WebApp.platform;
    if (!getToken) {
      this.userLogin();
    } else {
      this.getSeasons();
      this.getMyRankScore();
    }
    if (this.initDataUnsafe && this.initDataUnsafe.user) {
      localStorage.setItem("userTgId", this.initDataUnsafe.user.id);
    }
    // this.getUserData();
    /*const game = new Game();
    window.$game = game;
*/
    init();
   //console.log("******game", window, window.$game);

    this.bindEvents();

    localStorage.setItem("musicSwitch", this.musicSwitch.toString())
    const timer = setTimeout(() => {
        this.isAnimating = false;
        clearTimeout(timer);
      }, 1000);
    const that = this;
    window.onload = function() {
      that.showDialog()
    };
  },
  methods: {
    userLogin() {
      post("/tguser/login", { initData: this.initData })
        .then((response) => {
          if (response.code === 0) {
           //console.log(response.data);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("userAddress", response.data.user.nickName);
            localStorage.setItem("phone", response.data.user.phone);
            localStorage.setItem("userName", response.data.user.userName);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            this.getSeasons();
            this.getMyRankScore();
          }
        })
        .catch((error) => {
         //console.log(error);
        });
    },
    async getSeasons() {
      const res = await get("/season/get_seasons");
      if (res.code === 0) {
        this.season_id = res.data[0]?.id;
        if (this.season_id) {
          let data = res.data[0]?.name.split("~");
          let today = new Date();
          let year = today.getFullYear();
          let start_day = new Date(`${year}-${data[0]} 00:00:00`).getTime();
          let end_day = new Date(`${year}-${data[1]} 23:59:59`).getTime();
          let nowTime = today.getTime();
          return nowTime >= start_day && nowTime <= end_day;
        }
      }
    },
    async startGame() {
      // let bol = await this.getSeasons()
      this.showToast(true);
      const startpage = document.querySelector(".startPage");
      const maximumscore = document.querySelector(".maximumscore");
      
      startpage.style.display = "none";
      maximumscore.style.display = "flex";
      window.$game.start();
      const res = await post("/season/start_game", {
        seasonId: this.season_id + "",
      });
      if (res.code === 0) this.session_id = res.data.session_id;
    },
    async restartGame() {
      this.showToast(true);
      const restartpage = document.querySelector(".restartPage");
      const maximumscore = document.querySelector(".maximumscore");

      restartpage.style.display = "none";
      maximumscore.style.display = "flex";

      window.$game.restart();
      const res = await post("/season/start_game", {
        seasonId: this.season_id + "",
      });
      if (res.code === 0) this.session_id = res.data.session_id;
    },
    login() {
     //console.log("login");
    },
    async getMyRankScore() {
      const res = await get("/season/get_my_rank", {});
      if (res.code !== 0 || res.data.rank === -1) {
        // this.$toast.success("No data available at the moment");
      } else if (res.code === 0) {
        this.myRankHeightScore = res.data.game_points
      }
    },
    bindEvents() {
      const game = window.$game;
      const restartpage = document.querySelector(".restartPage");
      const scoreEl = document.querySelector(".score");
      const blindBox = document.querySelector(".blindBox");
      const maximumscore = document.querySelector(".maximumscore");

      

      game.failCallback = async (score) => {
        this.showToast(false);
        restartpage.style.display = "flex";
        blindBox.style.display = "none";
        maximumscore.style.display = "none";
        scoreEl.innerHTML = score;
        const res = await post("/season/end_game", {
          session_id: this.session_id,
          score: score,
          sign: "string",
          timestamp: Math.floor(new Date().getTime() / 1000),
        });
        if (res.code === 0) {
          this.myScore = score;
          this.myRankHeightScore = res.data.game_points
          this.AllHeightScore = res.data.max_game_points
        }
      };
    },
    updateParentValue(value) {
      this.isShowNavMenu = value;
      this.showToast(value);
     //console.log("updateParentValue", value);
    },
    typeDataFn(type){
      this.typeData = type;
     //console.log("typeDataFn", type)
      this.listData = this.typeData == 2 ? this.airdropList : this.gameList
     //console.log("typeDataFn", this.listData)
      this.isModal = true
    },
    musicSwitchFn(){
      this.musicSwitch = localStorage.getItem("musicSwitch") === "true";
      this.musicSwitch = !this.musicSwitch;
      localStorage.setItem("musicSwitch", this.musicSwitch.toString());
    },
    async goTelegramBot() {
      this.isAnimating = true;

      if (!secretAccessKey) {
        return false;
      }

      const s3 = new S3Client({
        region: region,
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey
        },
        forcePathStyle: true 
      });

      const divToCapture = document.getElementById('divToCapture');
      const canvas = await html2canvas(divToCapture);
      const blob = this.dataURItoBlob(canvas.toDataURL('image/png'));
      const phone = localStorage.getItem("phone") || "";
      
      const getTime = new Date().getTime();
      const time = getTime / 1000;
      const timestamp = Math.floor(time);
      
      const key = `share/${phone}${timestamp}.jpg`;

      const command = new PutObjectCommand({
        Bucket: bucketName,
        Key: key,
        Body: blob,
        ContentType: 'image/png'
      });

      try {
        const response = await s3.send(command);
        const url = `${endpoint}/${key}`;
        // console.log('Image uploaded successfully:', response);
        // console.log('Image URL:', url);
        if (url) {
            const timer = setTimeout(() => {
            this.isAnimating = false;
            clearTimeout(timer);
            const text = `\n Hey mate! \n I have reached ${this.myScore} points. \n 🎮 Jump and Earn Airdrop🪂 with me! Click below👇👇` + '\n' + this.fixedText + '?start=' + phone;
            const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
            if (Telegram.WebApp) {
              Telegram.WebApp.openTelegramLink(telegramShareUrl)
            }
          }, 100);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    joinFn(type) {
      const newObj = {
        'chanel': 'https://t.me/Nifis_channel',
        'chatgroup': 'https://t.me/Nifis_chat',
        'playhere': 'https://t.me/NifisHuntBot/app?startapp=7432874170',
      }
      if (Telegram.WebApp && type) {
        Telegram.WebApp.openTelegramLink(newObj[type])
      }
    },
    showDialog() {
      this.checkDialogCount()
      if (this.dialogCount < 1) {
        this.showJoin = true
        this.dialogCount++
        this.saveDialogCount()
      }
    },
    closeDialog() {
      this.showJoin = false
    },
    checkDialogCount() {
      const today = this.getTodayDate()
      const storedData = localStorage.getItem('dialogData')
      if (storedData) {
        const data = JSON.parse(storedData)
        if (data.date === today) {
          this.dialogCount = data.count
        } else {
          this.dialogCount = 0
          this.saveDialogCount()
        }
      } else {
        this.dialogCount = 0
      }
    },
    saveDialogCount() {
      const today = this.getTodayDate()
      const dialogData = {
        date: today,
        count: this.dialogCount
      }
      localStorage.setItem('dialogData', JSON.stringify(dialogData))
    },
    getTodayDate() {
      const today = new Date()
      return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
    }
  },
};
</script>

<style lang="scss">
@keyframes flicker {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.lamp {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  z-index: 1;
  overflow: hidden;
  animation: flicker 2s infinite;
  border-radius: 50%;
}

.lamp::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  opacity: 0.1;
  animation: flicker 2s infinite;
}

.lamp::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('@/assets/img/icons/game_tips.png') no-repeat center center/60%;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-1rem); }
  60% { transform: translateY(-0.5rem); }
}

.game-tips {
  position: absolute;
  bottom: 6.5rem;
  left: 1rem;
  width: 4.5rem;
  height: 4.5rem;
  background: url('@/assets/img/icons/airdrop_btn.png') no-repeat center center/100%;
  z-index: 1;
  animation: bounce 2s infinite;
}

.maximumscore {
  position: fixed;
  top: 1.3rem;
  right: .7rem;
  color: #333;
    display: flex;
    align-items: center;
    z-index: 999;
  .maximumscore-inner{
    padding: .5rem 1rem;
    display: flex;
    min-width: 8rem;
    background:#F3F3F3;
    border-radius: 2rem;
    box-shadow: 0px 4px 10px 0px rgba(167, 167, 167, 0.9);
    border: .2rem solid #DDC294;
  }
  .hint{
    word-wrap: break-word;
    width: 4rem;
    font-size: 0.6rem;
    margin-right:.3rem;
  }
  .myscore {
    padding-left:.3rem;
    font-size: 1.5rem;
    border-left: 1px solid #301804;
    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    background: linear-gradient(180deg, #9B7B46 0%, #301804 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    line-height: 2.5rem;
  }
  .music-switch{
    width: 2.5rem;height:2.5rem;
    margin-right:.7rem;
    background: url('@/assets/img/icons/sound_on.png') no-repeat center center/100%;
  }
  .music-switch-off{
    background: url('@/assets/img/icons/sound_off.png') no-repeat center center/100%;
  }
}
.maximumscore-hide {
  display: none;
}
.highestscore {
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.game-share{
  position: absolute;top: 1rem;right:1rem;
  width: 3rem;height: 3rem;
  z-index: 3;
  background: url('@/assets/img/icons/game_share.png') no-repeat center center/100%;
}
.logo {
  width: 100vw;
  height: 37vh;
  margin: 2rem 0;
  background: url('@/assets/img/quest/tappop_preview.png') no-repeat center center/contain;
}
.btn {
  width: 17rem;
  height: 7rem;
  background: url("@/assets/img/icons/start-game.png") no-repeat center
    center/contain;
}
.restart-btn {
  background-image: url("@/assets/img/icons/try-again.png");
}

.final-score {
  width: 100vw;
  height: 21rem;
  background: url("@/assets/img/icons/score_bg.png") no-repeat center center/83%,
    url("@/assets/img/icons/light.png") no-repeat center center/108%;
  .hint {
    margin-top: 5.5rem;
    color: #0c3ded;
    font-weight: bold;
    font-size: 1.1rem;
  }
  .score {
    font-size: 3.8rem;
    color: #0c3ded;
    font-weight: 600;
    line-height: 11rem;
  }
  .restartPage{
    justify-content: start;
    padding-top: 2rem;
  }
}
.home{
  .modal-body{
    max-height: 75vh;
    overflow: hidden;
    color: #3D3D3D ;
    padding: 2rem 1rem;
    .modal-ul{
      padding: 1rem .7rem;
      .typeli{
        display: flex;
        div{padding-right: .3rem;}
      }
      .modal-title{
        font-weight: bold;
        span{
          font-weight: normal;
        }
      }
      .childs-li{
        display: flex;
      }
      .drop{
        padding-right: .4rem;
      }
      .modal-table{
        width: 100%;
        padding-bottom: 1rem;
      }
      table {
        width: 100%;
        border-collapse: collapse;
      }
      
      th, td {
        padding: 10px;
        text-align: left;
        border: 1px solid rgba(201, 199, 199, 0.803);
      }
      
      th,tr {
        background-color: #f2f2f2;
      }
    }
  }
  .scrollable-content{
    overflow-y: auto;
    max-height: 65vh;
  }
  .scrollable-content::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .modal-content{
    border:none;
    background: url('@/assets/img/icons/airdrop_bg.png') no-repeat center center/100% 100%;
  }
}
#divToCapture{
  position: absolute;
  left: -110%;
  bottom: -110%;
  z-index: -1;
  background: url('@/assets/img/icons/index_bg_03.png');
  padding: 1rem 0;
}
.new-score{
  font-size: 3.8rem;
  color: #0c3ded;
  font-weight: 600;
  line-height: 11rem;
  padding-top: 7rem;
}
@keyframes joinscale {
  0% {
      transform:translate(-50%, -30%) scale(0.8);
  }
  100% {
      transform:translate(-50%, -30%) scale(1);
  }
}
.come-join{
  width: 85vw;height: 65vh;
  position: absolute;top: 30%;left: 50%;
  background: linear-gradient(180deg, #FFD015 0%, #FF7B00 98%);
  border-radius: 2rem;
  padding: .5rem;
  z-index: 9;
  animation: joinscale 0.3s forwards;
  .come-join-inner{
    width: 100%;height: 100%;
    padding-top: 1rem;
    position: relative;
    border-radius: 2rem;
    background: linear-gradient(180deg, #FFDB49 0%, #FFA51E 100%);
    box-shadow: inset 0px -2px 22px 9px #FFDCB2;
    .join-over{
      position: absolute;
      top: .2rem;right: .2rem;
      width: 2rem;height: 2rem;
      background: url('@/assets/img/icons/icon-join-over.png') no-repeat center center/100%;
    }
    .join-list{
      height: 13vh;
      width: 90%;
      margin: 0 auto;
    }
    .join-title{
      width: 83%;
      background: url('@/assets/img/icons/icon-join-title.png') no-repeat center center/100%;
    }
    .join-chanel{
      height: 11vh;
      cursor: pointer;
      background: url('@/assets/img/icons/icon-join-01.png') no-repeat center center/contain;
    }
    .join-group{
      height: 11vh;
      cursor: pointer;
      background: url('@/assets/img/icons/icon-join-02.png') no-repeat center center/contain;
    }
    .join-here{
      height: 11vh;
      cursor: pointer;
      background: url('@/assets/img/icons/icon-join-03.png') no-repeat center center/contain;
    }
    .join-nifis{
      background: url('@/assets/img/icons/icon-join-logo.png') no-repeat center center/100%;
    }
  }
}
</style>
