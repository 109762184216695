<template>
  <div class="mobiletransactions overflowyauto">
    <div class="inner">
      <div class="logo"></div>
      <div class="title">Wallect Connecting</div>
      <div class="user-info">
        <div class="chain-name" v-if="chainName"> <div>{{ chainName }}</div> </div>
        <div class="address" v-if="type == '2' && showAddress && claimTimeout" @click="getMyRankScore()">{{ showAddress? showAddress : 'Connect'}} </div>
        <div class="address" v-else>{{ showAddress? showAddress : 'Connect'}} </div>
      </div>
      <div class="rankingimg" :class="{ 'case-img-open': userScoreRank.is_claimed && claimTimeout }" v-if="type == '2' && showAddress && claimTimeout" @click="getMyRankScore()"></div>
    </div>
    <div v-if="type == '2' && showAddress && claimTimeout && isLoading">
      <div class="modal-loaing"></div>
      <div class="loading"></div>
      <div class="loading-text">Please do not close the window</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Web3 from 'web3';
import RewardDistributorAbi from '@/abis/RewardDistributorAbi'
import {get, post} from "@/lib/API";
import { useWeb3Modal, useWeb3ModalAccount, useSwitchNetwork, useWeb3ModalProvider } from '@web3modal/ethers/vue'
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import { getUserAgent } from '@/lib/isMobileDevice'
import axios from 'axios';

const routeUrl = process.env.ROUTE_URL || '../';
const payuContractAddress = process.env.CONTRACT_ADDRESS;
const tgLink = process.env.TG_LINK || '';

export default {
  name: 'MobileTransactions',
  components: {
    
  },
  data() {
    return {
      isMobileDevice: getUserAgent(),
      userAddress: '',
      token: this.$route.query.token || '',
      type: this.$route.query.type || '',
      gasPrice: this.$route.query.gasPrice || '',
      showAddress: localStorage.getItem('userAddress'),
      walletList: [
        {
          chainId: '0x61',
          chainIdNumber: '97',
          name: 'BNB Testnet',
          chainName: 'BNB Testnet',
          currency: 'ETH',
          explorerUrl: 'https://testnet.bscscan.com',
          rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545'
        },
        {
          chainId: '0xdf',
          chainIdNumber: '223',
          name: 'B2 Mainnet',
          chainName: 'B2 Mainnet',
          currency: 'BTC',
          explorerUrl: 'https://explorer.bsquared.network',
          rpcUrl: 'https://mainnet.b2-rpc.com'
        }
      ],
      walletObj:'',
      seasonId: '',
      chainName: '',
      userScoreRank: '',
      claimTimeout: '',
      isNetwork: false,
      isLoading: false,
      isClaimReward: false,
      isSecondTransaction: false,
      txHash: localStorage.getItem("txHash"),
      freeGasList: [
        { senderAddress:'MHg2M0Y5REU3ZjA0ZEQ1RkM1ZDY4MkE5ZTJENUNFMUVENzAyZjVCM2U3', privateKey: 'MzhmNDI3ODE2NjhmZjFlYzg4MjYwMmNkM2I3YTNmY2I5YTUxOWI5NzdlYTU4NzMxM2VkMWEzMDk4ZDViODg1Ng==' },
        { senderAddress:'MHg0ODg0MDI1YWQ0YmJlZTY0Q2JEMTdhZDE0YmVhYjBjMjE1ODU4ODMz', privateKey: 'ZTBlNzAwYjM1OGM2ODg1NTk5ZTU0MWJiN2UwN2ZjYTdmZDdmYmM2YjFhMjU3ZWZjMDJjNDFkNjc1MzlhN2RkZg==' },
      ],
      freeGasType: '1',
    }
  },
  async mounted() {
    if (this.token && this.type) {
      this.init();
    } else {
      const url = window.location.href;
      const tokenIndex = url.indexOf("token=");
      const typeIndex = url.indexOf("/type=");
      let token = "";
      let type = "";
      if (tokenIndex !== -1 && typeIndex !== -1) {
        const urlObject = new URL(url);
        const searchParams = new URLSearchParams(urlObject.search);
        const gettoken = String(searchParams.get('token'));
        token = String(gettoken.split('/')[0]);
        type = String(gettoken.split('/').pop().split('=')[1]);
      }
      if (token && type){
        this.token = token;
        this.type = type;
        this.init();
      }
    }
    if (this.showAddress) {
      this.showAddress = this.truncateString(this.userAddress)
    }
    this.checkCurrentChain()
    this.walletObj = this.walletList[1]
    // if (this.tgLink === 'https://t.me/tappopbot') {
    //   this.walletObj = this.walletList[1]
    // } else {
    //   this.walletObj = this.walletList[0]
    // }
  },
  methods: {
    async init() {
      localStorage.setItem('token', this.token)
      this.getMyRankScore();
    },
    async getMySign() {
        if (!this.token) {
          this.isClaimReward = false;
          return false;
        }
        const that = this;
      try {
        const getUseraddress = await this.initAccounts();
        if (getUseraddress) {
          this.showAddress = this.truncateString(getUseraddress)
        }
        if (getUseraddress && this.type && this.type == '2') {
          post('/distribution/claimReward', { seasonId: this.seasonId , userAddress: getUseraddress})
            .then(response => {
              if (response.code === 0) {
                const { seasonId, reward_list, unique_id, sign } = response.data;
                if (reward_list && reward_list.length > 0) {
                  const getRewardList = that.rewardList(reward_list);
                  if (seasonId && getRewardList.length > 0 && getUseraddress && unique_id && sign) {
                    // that.userClaimTransaction(seasonId, getRewardList, getUseraddress, unique_id, sign);
                    that.gosendTransaction(seasonId, getRewardList, getUseraddress, unique_id, sign);
                  } else {
                    this.isClaimReward = false;
                  }
                } else {
                  this.isClaimReward = false;
                }
              } else {
                this.isClaimReward = false;
                if (response.msg === 'UserDidNotParticipateInThisSeason') {
                  that.$toast.error('User did not participate in this season.');
                } else {
                  this.$toast.error('Transaction Failed.');
                }
              }
            })
            .catch(error => {
             //console.log(error);
            });
        }
      } catch (err) {
       //console.log(err);
      }
      
    },
    rewardList(list) {
      const web3 = new Web3(window.ethereum);
      return list.map(item => {
        //const weiValue = web3.utils.toWei(item.amount, 'ether')
        return [item.tokenAddress, item.amount];
      });
    },
    initializeGame() {
     //console.log('initialize game');
    },
    itemTypeFn(value) {
      this.itemType = value;
    },
    async initAccounts() {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        if ( accounts.length > 0 ) {
          this.userAddress = accounts[0];
          if (this.type == '1') {
            this.connectWallet(accounts[0])
          }
          this.showAddress = this.truncateString(accounts[0])
          return accounts[0];
        }
      } catch (err) {
      }
    },
    //关联钱包
    connectWallet(walletAddress) {
      post('/tguser/bind_wallet', { walletAddress: walletAddress})
        .then(async response => {
          if (response.code === 0) {
            localStorage.setItem('userAddress', walletAddress)
            this.showAddress = this.truncateString(walletAddress)
            this.$toast.success('Wallet successfully bound.');
            this.isClaimReward = false;
          } else {
            // this.$toast.error(response.msg);
          }
        })
        .catch(error => {
         //console.log(error)
        });
    },
    async userClaimTransaction(season, receiverInfos, userAddress, uniqueId, sign) {  
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(RewardDistributorAbi, payuContractAddress);
      try {
        const getTransaction = { from: userAddress, gas: 500000, gasPrice: 1000000 };
        const getNotTransaction = { from: userAddress };
        const newTransaction = this.gasPrice == '1'? getNotTransaction : getTransaction;
        this.isLoading = true;
        await contract.methods.distribute(season, receiverInfos, userAddress, uniqueId, sign)
          .send(newTransaction)
          .on('receipt', (receipt) => {
             console.log("Transaction receipt:", receipt);
              if (receipt.logs.length > 0) {
                this.userPaid(true, receipt.transactionHash, uniqueId);
              }
          })
          .on('error', (error) => {
              // console.error("Error calling distribute-01:", error);
              const transactionHash = error?.receipt?.transactionHash || null;
              console.log('distribute-01 hash:', transactionHash);
              if (this.isNetwork) {
                return false;
              }
              this.isNetwork = true;
              this.getNetwork(transactionHash, uniqueId);
              return false;
          });
      } catch (error) {
        // console.error("Error calling distribute-02:", error);
        // Get the transaction hash if available
        const transactionHash = error?.receipt?.transactionHash || null;
        console.log('distribute-02 hash:', transactionHash);
        if (this.isNetwork) {
          return false;
        }
        this.isNetwork = true;
        this.getNetwork(transactionHash, uniqueId);
        return false;
      }
      
		},
    async gosendTransaction(
      seasonId,
      getRewardList,
      getUseraddress,
      unique_id,
      sign
    ) {
      const that = this;
      const rpcUrl = 'https://mainnet.b2-rpc.com';
      const chainIdNumber = 223;
      const senderAddress = this.freeGasType === '1' ? this.freeGasList[0].senderAddress : this.freeGasList[1].senderAddress;
      const privateKey = this.freeGasType === '1' ? this.freeGasList[0].privateKey : this.freeGasList[1].privateKey;
      const web3 = new Web3(rpcUrl);
      const nonce = await web3.eth.getTransactionCount(atob(senderAddress));
      const contract = new web3.eth.Contract(
        RewardDistributorAbi,
        payuContractAddress
      );
      const gasPrice = 1100000;
      const nonceList = ['nonce too low', 'replacement transaction underpriced', 'nonce already used', 'invalid nonce'];
      console.log('nonce:', web3.utils.toHex(nonce))
      try {
        const transaction = {
          nonce: web3.utils.toHex(nonce),
          gasPrice: gasPrice,
          gas: 500000,
          from: getUseraddress,
          to: payuContractAddress,
          chainId: web3.utils.toHex(chainIdNumber),
          data: contract.methods
            .distribute(
              seasonId,
              getRewardList,
              getUseraddress,
              unique_id,
              sign
            )
            .encodeABI(), // Contract functions and parameters
        };
        const signedTx = await web3.eth.accounts.signTransaction(transaction, atob(privateKey));
        this.isLoading = true;
        const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction)
          .on('transactionHash', function(hash){
            if (hash) {
              localStorage.setItem("txHash", hash);
              that.txHash = hash;
            }
          })
          .then(function(receipt) {
            // console.log('Transaction receipt:', receipt);
            if(receipt.logs.length > 0) {
              that.userPaid(true, receipt.transactionHash, unique_id);
            }
          })
          .catch(function(error) {
            //console.log('Error001:', error.message);
            if (error.message) {
              if (error.message.indexOf('UniqueId Already Distributed') > -1 || error.message.indexOf('Season To Already Distributed') > -1){
                that.$toast.error('Rewards already claimed!');
                that.userPaid(true, '', unique_id);
                that.claimTimeout = true;
              } else {
                that.restartTransaction(seasonId, getRewardList, getUseraddress, unique_id, sign, 1);
              }
            }
          }); 
      } catch (error) {
        //console.log("Error002:", error);
        let isDataError = 1;
        for (const key in error) {
          if (error.hasOwnProperty(key)) {
            if(error[key]) {
              const message = String(error[key]);
              if(message.indexOf('UniqueId Already Distributed') !== -1 || message.indexOf('Season To Already Distributed') !== -1) {
                that.$toast.error('Rewards already claimed!');
                that.userPaid(true, '', unique_id);
                that.claimTimeout = true;
                isDataError = 2;
              }
            }
          }
        }
        that.restartTransaction(seasonId, getRewardList, getUseraddress, unique_id, sign, isDataError);
      }
    },
    async restartTransaction(seasonId, getRewardList, getUseraddress, unique_id, sign, isDataError) {
      const that = this;
      if (that.isSecondTransaction) {
        that.userPaid(false, '', unique_id);
        that.$toast.error('The network is busy, please try again later');
        this.isClaimReward = false;
        return false;
      }
      const timer = setTimeout(() => {
        if (isDataError === 1){
          that.isSecondTransaction = true;
          that.freeGasType = '2';
          that.gosendTransaction(seasonId, getRewardList, getUseraddress, unique_id, sign);
          clearTimeout(timer);
        }
      }, 2000);
    },
    async getContract(season, receiverInfos, userAddress, uniqueId, sign) {
      const myContractAddress = process.env.CONTRACT_ADDRESS
      const { walletProvider, walletProviderType } = useWeb3ModalProvider()
      const { address, chainId, isConnected } = useWeb3ModalAccount()
      if (isConnected && address.value && walletProvider.value) {
        const providera = new BrowserProvider(walletProvider.value);
        const signer = await providera.getSigner();
        const contract = new Contract(myContractAddress, RewardDistributorAbi, signer);
        try {
         //console.log(address.value);
          const tx = await contract.distribute(season, receiverInfos, address.value, uniqueId, sign);
         //console.log("Transaction hash:", tx.hash);
          const receipt = await tx.wait();
         //console.log("Transaction receipt:", receipt);
        } catch (error) {
          //console.error("Error calling distribute:", error);
        }
      } else {
       //console.log('Not connected')
        const getModal = useWeb3Modal()
        getModal.open()
      }
    },
    async userPaid(isSuccess, hash, uniqueId) {
      //console.log('userPaid', isSuccess, hash, uniqueId);
      const res = await post('/distribution/userPaid', { is_success: isSuccess , tx_hash: hash, unique_id: uniqueId})
      if (res.code === 0) {
        this.isSecondTransaction = false;
        const getAddress = this.truncateString(this.showAddress);
        if (isSuccess) {
          this.$toast.success(`The reward has been claimed at ${getAddress}!`);
          this.claimTimeout = true;
          this.userScoreRank.is_claimed = true;
        }
        this.isLoading = false;
        this.isNetwork = false;
        this.isClaimReward = false;
        this.freeGasType = '1';
      }
    },
    truncateString(str) {
      const firstPart = str.substring(0, 3);
      const lastPart = str.substring(str.length - 3);
      return `${firstPart}...${lastPart}`;
    },
    truncateAddressString(str) {
      const firstPart = str.substring(0, 5);
      const lastPart = str.substring(str.length - 4);
      return `${firstPart}....${lastPart}`;
    },
    async createdChain() {
      try {
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
       //console.log('chainId', chainId);

        if (chainId && (chainId != this.walletObj?.chainId || chainId != this.walletObj?.chainIdNumber)) {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: this.walletObj?.chainId }],
            });
            this.chainName = this.walletObj.chainName
            this.getMySign();
          } catch (switchError) {
            if (switchError.code === 4902 || switchError.code === -32603) {
              try {
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: this.walletObj?.chainId,
                      chainName: this.walletObj?.chainName,
                      nativeCurrency: {
                        name: this.walletObj?.currency,
                        symbol: this.walletObj?.currency,
                        decimals: 18,
                      },
                      rpcUrls: [this.walletObj?.rpcUrl],
                      blockExplorerUrls: [this.walletObj?.explorerUrl],
                    },
                  ],
                });
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: this.walletObj?.chainId }],
                });
                this.chainName = this.walletObj.chainName
                this.getMySign();
              } catch (addError) {
               //console.log(addError);
              }
            }
            if (switchError.code === 4001) {
              //console.error('User cancels switch');
            }
          }
        } else {
          this.chainName = this.walletObj.chainName
          this.getMySign();
        }
      } catch (error) {
        //console.error('Failed to obtain chainId', error);
      }
    },
    async getMyRankScore() {
      if(this.isClaimReward){
        return false;
      }
      this.isClaimReward = true;
      const res = await get("/season/get_my_rank", {});
      if (res.code === 0) {
        this.userScoreRank = res.data;
        if (this.type == '2') {
          if (this.userScoreRank.has_reward && this.userScoreRank.is_claimed) {
            const getAddress = this.truncateAddressString(this.showAddress);
            this.$toast.error(`The reward has been claimed at ${getAddress}`);
            this.claimTimeout = true;
            await this.initAccounts();
            this.isClaimReward = false;
            if(this.txHash) {
              const timer = setTimeout(() => {
                window.open(`https://explorer.bsquared.network/tx/${this.txHash}`, "_blank");
                clearTimeout(timer);
              }, 2000);
            }
            return false;
          } else {
            this.seasonId = res.data.latest_season_id;
            this.getSeasons();
          }
        } else {
          await this.initAccounts();
          this.isClaimReward = false;
        }
      }
    },
    async fetchChainListData(chainId) {
      fetch('https://chainid.network/chains.json')
      .then(response => response.json())
      .then(data => {
        data.forEach(chain => {
          if (chain.chainId === Number(chainId)) {
            this.chainName = chain.name;
          }
        });
      })
      .catch(error => console.log(error));
    },
    async checkCurrentChain() {
      try {
        const networkVersion = await window.ethereum.request({ method: 'net_version' });
       //console.log('networkVersion', networkVersion);
        if (networkVersion){
          this.fetchChainListData(networkVersion);
        }
      } catch (error) {
        console.error('Error checking current chain:', error);
      }
    },
    async getSeasons() {
      const res = await get("/season/get_seasons");
      if (res.code === 0) {
        const isNewSeasonObj = Object.keys(res.data[0]).length;
        const isHistorySeasonObj = Object.keys(res.data[1]).length;

        let newSeasonObj = isNewSeasonObj > 0 ? res.data[0] : null;
        let historySeasonObj = isHistorySeasonObj > 0 ? res.data[1] : null;

        if (isHistorySeasonObj > 0 && isNewSeasonObj === 0) {
          newSeasonObj = res.data[1];
        }

        if (newSeasonObj) {
          // Timeout, in seconds
          let targetObj = null;
          if (this.seasonId === newSeasonObj?.id) {
            targetObj = newSeasonObj;
          } else if (this.seasonId === historySeasonObj?.id) {
            targetObj = historySeasonObj;
          }
          if (targetObj) {
            this.collectionTime = parseInt(Number(targetObj.gift_expire_days) * 24 * 60 * 60);
            const getEndData = this.processingTime(targetObj.end_time);
            this.getUserTime(this.collectionTime, getEndData);
          }
        }
      }
    },
    processingTime(time) {
      if ( time ) {
        const timestamp = Date.parse(time) / 1000;
        return parseInt(timestamp);
      } else {
        const date = new Date();
        const utcTimestamp = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
        return parseInt(utcTimestamp/1000);
      }
    },
    async getUserTime(collectionTime, getEndData) {
      try {
        const response = await axios.get('https://worldtimeapi.org/api/ip');
        const getTimeT0 = response.data.unixtime ? this.processingTime(response.data.utc_datetime) : this.processingTime();
        if (getTimeT0 > collectionTime + getEndData) {
          //Overdue collection
          this.claimTimeout = false;
          this.isClaimReward = false;
         console.log('Overdue collection');
        } else {
          //Not exceeding the time limit for collection
          this.claimTimeout = true;
          this.getMySign();
         console.log('Not exceeding the time limit for collection');
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getNetwork(txHash, unique_id) {
      const that = this;
      if (!txHash) {
        that.userPaid(false, '', unique_id);
        return false;
      }
      const url = `https://explorer.bsquared.network/api/trpc/transaction.getTxDetail?input=%7B%22json%22%3A%22${txHash}%22%7D`;
      try {
        await axios.get(url)
        .then(response => {
          if (response.status == 200){
            const getRevertReason = response.data?.result?.data?.json?.revert_reason || '';
            if (response.data?.result?.data?.json === null) {
              const imgTime = setTimeout(() => {
                that.getNetwork(txHash, unique_id)
                clearTimeout(imgTime);
              }, 3000);
            } else {
              if(getRevertReason && (getRevertReason.indexOf('UniqueId Already Distributed') !== -1 || getRevertReason.indexOf('Season To Already Distributed') !== -1)){
                that.$toast.error('Rewards already claimed!');
                that.userPaid(true, txHash, unique_id);
                that.claimTimeout = true;
              } else {
                that.userPaid(false, '', unique_id);
              }
            }
          } else {
            that.userPaid(false, '', unique_id);
          }
        }).catch(error => {
          that.userPaid(false, '', unique_id);
        }
      );
      } catch (error) {
        console.error(error);
      }
    },
  }
}
</script>
<style>
body{
  padding: 0;margin: 0;
  height: 100vh;width: 100vw;
  background: url('@/assets/img/icons/index_bg_03.png');
  overflow: hidden;
}
</style>
<style lang="scss" scoped>
.inner{
  font-size: 1.5rem;
  padding-top: 2rem;
}
.logo {
  width: 100vw;
  height: 30vh;
  background: url('@/assets/img/quest/tappop_preview.png') no-repeat center center/contain;
}
.title{
  width: 100%;font-size: 1.5rem;
  font-weight: bold;text-align: center;margin: 2rem 0;
}
.user-info{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  .chain-name{
    height: 3rem;
    border-radius: 1rem;color: #fff;font-size: 1rem;
    padding:0 1.4rem 0 2.1rem;
    color: #3D3D3D;
    background: url('@/assets/img/icons/Wallet-01_01.png') no-repeat left center/auto 100%,
    url('@/assets/img/icons/Wallet-01_03.png') no-repeat right center/auto 100%;
    div{
      padding-top: .5rem;
      display: inline-block;
      height: 100%;width: 100%;
      line-height: 1.7rem;font-size: .8rem;
      background: url('@/assets/img/icons/Wallet-01_02.png') repeat-x center center/100% 100%,
    }
  }
  .address{
    height: 3rem;
    border-radius: 1rem;
    font-size: 0.8rem;
    line-height: 2.8rem;
    padding:0 1rem 0 2.5rem;
    color: #3D3D3D;
    span{
      position: relative;
      top: -.5rem;
      font-size: .8rem;
    }
    min-width: 7.5rem;
    background: url('@/assets/img/icons/Wallet-02.png') no-repeat center center/100% 100%;
  }
}
.rankingimg{
  margin:2rem auto 0;
  width: 3rem;height: 3rem;
  background: url('@/assets/img/icons/treasure-chest-shut.png') no-repeat center center/90%;
}
.case-img-open{
  background:url("@/assets/img/icons/treasure-chest.png") no-repeat center
          center/90%;
}
.modal-loaing{
  width: 100vw;height: 100vh;
  background: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;left: 0;
  z-index: 9;
}
.loading{
  position: fixed;
  top: 46%;left: 46%;
  transform: translate(-50%, -50%);
  width: 3rem;height: 3rem;
  z-index: 10;
  background: url('@/assets/img/icons/loading.png') no-repeat center center/contain;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-text{
  position: fixed;
  top: 46%;
  left: 46%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 100%;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  margin-top: 6rem;
}
</style>
