const API_DOMAIN = process.env.API_DOMAIN || '';

export function post(endpoint, data) {
  const token = localStorage.getItem('token');
  const formData = Object.entries(data).map(
    ([key, value]) =>
      value !== '' ? { [key]: value } : ''
  ).filter(Boolean);
  let newObj = {}
  formData.forEach((item) => {
    newObj = { ...newObj, ...item }
  })
  return fetch(`${API_DOMAIN}${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-token': token ?? '',
    },
    body: JSON.stringify(newObj),
  }).then(res =>  {
    if (res.status === 200) {
      return res.json().then(result => {
        if (result.code === 0) {
          return result;
        }
        if (result.code != 0) {
          userLogin(result.msg);
        }
        return result;
      })
    }
    if (res.status === 500) {
      return res.json().then(result => {  
        if (result.code === 500){
          switch( result.msg ) {  
            case "Invalid Token":  
              // login timeout
              window.localStorage.clear();
              window.location.reload();
              break;  
            default:  
              return {
                code: result.code,
                msg: result.msg
              }
          }
        }
      })
    }
  });
}

export function get(url, data = {}) {
  try {
    let query = '';
    const token = localStorage.getItem('token');
    Object.entries(data).forEach(([key, value], index) => {
      if (index === 0) {
        query = '?' + `${key}=${value}`;
      } else {
        query += '&' + `${key}=${value}`;
      }
    });
    return fetch(`${API_DOMAIN}${url}${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        'x-token': token ?? '',
      }
    }).then(response => {
      return response.json();
    }).then(res => {
      if (res.code === 0) {
        return res;
      }
      if (res.code != 0) {  
        userLogin(res.msg);
      }
      return {
        code: res.code,
        msg: res.msg
      }
    }).catch(error => {
      return {
        code: 400,
        msg: 'Request failure!'
      }
    });
  } catch (error) {
    return {
      code: 400,
      msg: 'Request failure!'
    }
  }
}

async function userLogin(msg) {
  if (msg.indexOf('Token') > -1 || msg.indexOf('token') > -1 || msg.indexOf('未登录或非法访问') > -1 || msg.indexOf('record not found') > -1 || msg.indexOf('授权已过期') > -1) {
    window.localStorage.clear();
    const  initData = Telegram.WebApp.initData || '';
    if (initData) {
      post("/tguser/login", { initData: initData })
      .then((response) => {
        if (response.code === 0) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userAddress", response.data.user.nickName);
          localStorage.setItem("phone", response.data.user.phone);
          localStorage.setItem("userName", response.data.user.userName);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          window.location.reload();
        }
      })
      .catch((error) => {
       //console.log(error);
      });
    }
  }
}