'use strict';

const DEF = {
	scale: {
		x: 0.4,
		y: 0.415,
		z: 0.415,
	},
	rotation: {
		x: 0,
		y: 0,
		z: 0,
	},
	position: {
		x: 0,
		y: 0,
		z: 0,
	},
};

class ModelConfig {
	constructor() {
		this.objList = [
			'shoutixiang',
			'shudun',
			'x_hezi5',
			'q_hezi4',
			'd_hezi4',
			'd_hezi5',
			'pingtai1',
			'huaban',
			'dangao',
			'x_fangzhuo',
			'x_huaban',
			'x_yuanzhu1',
			'x_xigua',
			'q_shudun',
			'd_tiaotai',
			'd_xiaoniao',
			'd_pixiang',
			'd_yuanzhu1',
			'cheng',
			'cd',
		];

		this.d_xiaoniao = {
			scale: DEF.scale,
			rotation: {
				x: 0,
				y: -1 / 2,
				z: 0,
			},
			position: {
				x: 0,
				y: 0,
				z: 0,
			},
		};

		this.shoutixiang = {
			scale: DEF.scale,
			rotation: {
				x: 0,
				y: -1 / 2,
				z: 0,
			},
			position: {
				x: 0,
				y: 0,
				z: 0,
			},
		};

		this.d_pixiang = {
			scale: DEF.scale,
			rotation: {
				x: 0,
				y: -1 / 2,
				z: 0,
			},
			position: {
				x: 0,
				y: 0,
				z: 0,
			},
		};

		this.shudun = {
			scale: DEF.scale,
			rotation: {
				x: 0,
				y: -1 / 2,
				z: 0,
			},
			position: {
				x: 0,
				y: 0,
				z: 0,
			},
		};

		this.x_hezi5 = {
			scale: {
				x: 0.4,
				y: 0.415,
				z: 0.4,
			},
			rotation: DEF.rotation,
			position: DEF.position,
		};

		this.q_hezi4 = {
			scale: {
				x: 0.4,
				y: 0.415,
				z: 0.4,
			},
			rotation: DEF.rotation,
			position: DEF.position,
		};

		this.d_hezi4 = {
			scale: {
				x: 0.4,
				y: 0.415,
				z: 0.4,
			},
			rotation: DEF.rotation,
			position: DEF.position,
		};

		this.d_hezi5 = {
			scale: {
				x: 0.4,
				y: 0.415,
				z: 0.4,
			},
			rotation: DEF.rotation,
			position: DEF.position,
		};

		this.pingtai1 = DEF;
		this.huaban = DEF;
		this.dangao = DEF;
		this.x_fangzhuo = DEF;
		this.x_huaban = DEF;
		this.x_yuanzhu1 = DEF;
		this.x_xigua = DEF;
		this.q_shudun = DEF;
		this.d_tiaotai = DEF;
		this.d_yuanzhu1 = DEF;
		this.cheng = DEF;
		this.cd = DEF;
	}
}

export default ModelConfig;
