import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Friends from '../views/Friends.vue'
import Quest from '../views/Quest.vue'
import Ranking from '../views/Ranking.vue'
import MobileTransactions from '../views/MobileTransactions.vue'
import AboutView from '../views/AboutView.vue'

const routes = [
  {
    path: '/',
    name: 'aboutview',
    component: AboutView,
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
  },
  {
    path: '/friends',
    name: 'Friends',
    component: Friends
  },
  {
    path: '/quest',
    name: 'Quest',
    component: Quest
  },
  {
    path: '/ranking',
    name: 'Ranking',
    component: Ranking
  },
  {
    path: '/mobiletransactions',
    name: 'MobileTransactions',
    component: MobileTransactions
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
