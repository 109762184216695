<template>
  <div class="quest overflowyauto">
    <div class="quest-inner">
      <div class="wallet" @click="openWallet(1)"></div>
      <div class="user">
        <div class="user-img">
          <img v-if="userAvatar" :src="userAvatar" alt="" srcset="" @error="setDefaultImg">
          <img v-else src="@/assets/img/friends/user-img.svg" alt="" srcset="">
        </div>
        <div class="user-name" v-if="userName">{{userName}}</div>
        <div class="user-integral" @click="getUserData()">
          <img src="@/assets/img/friends/points.png" alt="" srcset="">
          <span>{{allPoints}}</span>
        </div>
        <div class="user-bg"></div>
      </div>
      <div class="daily-bot-bg-top" v-if="dailyList.length > 0"></div>
      <div class="daily-tasks" v-if="dailyList.length > 0">
        <div class="quest-title"></div>
        <div class="list">
          <ul>
            <li v-for="(item,index) in dailyList" :key="index" @click="dailyTasks(item)" :class="{'active':item.TaskFinished?.isClaimed}">
              <div class="tasks-li">
                <div class="listimg" :style="{ backgroundImage: 'url(' + item.Task?.icon + ')' }"></div>
                <div class="name">
                  <div class="title">{{item.Task?.name}}</div>
                </div>
                <div class="listtype"><div class="integral">X{{item.Task?.points}}</div></div>
              </div>
              <div class="tasks-bg"></div>
              <div class="tasks-ok"></div>
              <div class="tasks-ok-img"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="daily-bot-bg" v-if="dailyList.length > 0"></div>
      <div class="tasks-bot-bg-top" v-if="basicList.length > 0"></div>
      <div class="daily-tasks basic-tasks" v-if="basicList.length > 0">
        <div class="quest-title"></div>
        <div class="list">
          <ul>
            <li v-for="(item,index) in basicList" :key="index" @click="dailyTasks(item)" :class="{'active':item.TaskFinished?.isClaimed}">
              <div class="tasks-li">
                <div class="listimg" :style="{ backgroundImage: 'url(' + item.Task?.icon + ')' }"></div>
                <div class="name">
                  <div class="title">{{item.Task?.name}}</div>
                </div>
                <div class="listtype"><div class="integral">X{{item.Task?.points}}</div></div>
              </div>
              <div class="tasks-bg"></div>
              <div class="tasks-ok"></div>
              <div class="tasks-ok-img"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="tasks-bot-bg" v-if="basicList.length > 0"></div>
    </div>
    <nav-menu on="quest" :showValue="false"></nav-menu>
    <all-wallet :showWallet="showWallet" type="1" @childClick="updateParentValue" @walletClick="getWallet"></all-wallet>
  </div>
</template>

<script>
import { ref } from 'vue';
import NavMenu from '@/components/NavMenu.vue'
import AllWallet from '@/components/AllWallet.vue'
import RewardDistributorAbi from '@/abis/RewardDistributorAbi'
import { getUserAgent } from '@/lib/isMobileDevice'
import { useWeb3Modal, useWeb3ModalAccount, useSwitchNetwork, useWeb3ModalProvider } from '@web3modal/ethers/vue'
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import {get, post} from "@/lib/API";
import axios from 'axios';


const routeUrl = process.env.ROUTE_URL || '../';
const projectId = process.env.PROJECT_ID || '';

export default {
  name: 'Quest',
  components: {
    NavMenu,
    AllWallet
  },
  data() {
    return {
      dailyList: localStorage.getItem("dailyList") ? JSON.parse(localStorage.getItem("dailyList")) : [],
      basicList: localStorage.getItem("basicList") ? JSON.parse(localStorage.getItem("basicList")) : [],
      walletAddress: '',
      getModal: useWeb3Modal(),
      showWallet: false,
      userAgent: '',
      userAddress:localStorage.getItem('userAddress') || '',
      userName:localStorage.getItem('userName') || '',
      userAvatar:localStorage.getItem('avatar') || '',
      allPoints: 0,
      getWalletItem: '',
      unixtime: '',
      unwatch: null, 
      tgId:localStorage.getItem('phone') || '',
      currentItem: '',
    };
  },
  activated() {
    this.init()
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.currentItem = localStorage.getItem('currentItem') ? JSON.parse(localStorage.getItem('currentItem')) : '' || '';
      this.getUserTiem();
      this.getMyTaskBasicList();
      this.getTgUserData();
    },
    openWallet(type) {
      // Use this browser
      //getModal.open();
      // Use this on mobile devices
      const isMobileDevice = getUserAgent();
     //console.log(isMobileDevice)
      this.userAddress = localStorage.getItem('userAddress') || '';
      if(this.userAddress) {
        if (type === 2){
          this.connectWallet(this.userAddress)
        } else {
          const showAddress = this.truncateString(this.userAddress) + "  Copy Successful!";
          const txt = this.userAddress;
          this.copyToClipboard(txt, showAddress);
        }
      } else {
        window.Telegram.WebApp.showConfirm(`Do you confirm to bind the wallet(Cannot change later)?`, (result) => {
          if (result) {
            if (isMobileDevice) {
              this.showWallet = true;
            } else {
              const { address } = useWeb3ModalAccount()
              if(address.value) {
                this.connectWallet(address.value)
              } else {
                // Monitor changes in connection status
                if (this.unwatch) {
                  this.unwatch();
                }
                
                const { address, chainId, isConnected } = useWeb3ModalAccount()
                this.unwatch = this.$watch(
                  () => isConnected.value,
                  (newIsConnected, oldIsConnected) => {
                    if (newIsConnected && address.value) {
                      this.$toast.success('Wallet connected successfully.');
                     //console.log('getChainId',getChainId, chainId.value);
                      this.connectWallet(address.value)
                    } else {
                     //console.log('Wallet disconnected');
                    }
                  }
                );
                this.getModal.open();
              }
            }
          } else {
            return false;
          }
        });
      }
    },
    async getUserData() {
      const { address, chainId, isConnected } = useWeb3ModalAccount()
      localStorage.setItem('userAddress', address.value)
     //console.log(address.value, chainId.value, isConnected.value)
    },
    async setSwitchNetwork() {
      try {
        const chainId = 1
        const { switchNetwork } = useSwitchNetwork()
        switchNetwork(chainId)
      } catch (err) {
       //console.log(err)
      }
    },
    updateParentValue(value) {
      this.showWallet = value;
     //console.log('showWallet', value);
    },
    connectWallet(walletAddress) {
      post('/tguser/bind_wallet', { walletAddress: walletAddress})
        .then(async response => {
          if (response.code === 0) {
            localStorage.setItem('userAddress', walletAddress)
            const res = await get("/task/finish", { task_id: this.getWalletItem.Task?.ID });
            if (res.code === 0) {
              this.init();
              this.$toast.success(`You get ${this.getWalletItem.Task?.points} points!`);
            } else {
              if (walletAddress) {
                this.$toast.success(this.truncateString(walletAddress));
              } else {
                this.$toast.error(res.msg);
              }
            }
          }
        })
        .catch(error => {
         //console.log(error)
        });
    },
    //Daily Task Single Task
    dailyTasks(item) {
      if (!item.TaskFinished?.isClaimed) {
        if (item.Task?.gotoUrl) {
          const url = item.Task.gotoUrl;
          const minTime = 5000;
          const maxTime = 8000;
          const randomTime = Math.floor(Math.random() * (maxTime - minTime + 1)) + minTime;
          const imgTime = setTimeout(() => {
            this.upDateFinish(item)
            clearTimeout(imgTime);
          }, randomTime);
          if (url.indexOf('https://t.me/') > -1 || url.indexOf('https://telegram.me') > -1){
            if (url.indexOf('bot&text') > -1 && this.tgId){
              const getUrl = url.replace(/bot&text/g, "bot?start="+this.tgId+"&text");
              localStorage.setItem('currentItem', JSON.stringify(item));
              Telegram.WebApp.openTelegramLink(getUrl)
            } else {
              localStorage.setItem('currentItem', JSON.stringify(item));
              Telegram.WebApp.openTelegramLink(item.Task.gotoUrl)
            }
          } else {
            localStorage.setItem('currentItem', JSON.stringify(item));
            Telegram.WebApp.openLink(item.Task.gotoUrl);
          }
        } else {
          this.upDateFinish(item)
        }
      } else {
        this.$toast.error(`You have completed the task`);
      }
    },
    getMyTaskDailyList() {
      get('/task/getMyTaskList', { page: 1, pageSize: 50, type: 'daily' })
        .then(response => {
          if (response.code === 0) {
            this.dailyList = response.data.list || []
            this.dailyList.map(item => {
              if (item.TaskFinished?.date && item.TaskFinished?.date != this.unixtime) {
                item.TaskFinished.isClaimed = '';
              }
              if (this.currentItem && item.Task?.ID === this.currentItem.Task?.ID && !item.TaskFinished.isClaimed){
                this.taskFinish(item)
              }
            })
            localStorage.setItem("dailyList", JSON.stringify(this.dailyList));
          }
        })
        .catch(error => {
         //console.log(error)
        });
    },
    getMyTaskBasicList() {
      get('/task/getMyTaskList', { page: 1, pageSize: 50, type: 'basic' })
        .then(response => {
          if (response.code === 0) {
            const list = response.data.list || []
            list.map(item => {
              if (item.Task?.name === 'Connect Wallet') {
                this.getWalletItem = item;
                const getWallet = localStorage.getItem('userAddress') || '';
                if (!this.getWalletItem.TaskFinished?.isClaimed && getWallet ){
                  this.taskFinish(this.getWalletItem)
                }
              } else {
                if (this.currentItem && item.Task?.ID === this.currentItem.Task?.ID && !item.TaskFinished.isClaimed){
                  this.taskFinish(item)
                }
              }
            })
            this.basicList = list
            localStorage.setItem("basicList", JSON.stringify(this.basicList));
          }
        })
        .catch(error => {
         //console.log(error)
        });
    },
    async getTgUserData() {
      const res = await get("/tguser/myTelegramUser");
      if (res.code === 0) {
        const user = res.data.user;
        this.userAvatar = user.avatar;
        this.allPoints = user.task_points + user.game_points;
        this.userName = user.username;
        this.userAddress = user.wallet_address;
        localStorage.setItem('userAddress', this.userAddress)
      }
    },
    async upDateFinish(item) {
     //console.log(item.Task?.name)
      switch (item.Task?.name) {
        case 'Connect Wallet':
          this.openWallet(2);
          break;
        default:
          const timer = setTimeout(() => {
            this.taskFinish(item);
            clearTimeout(timer);
          }, 500);
          break;
      }
    },
    async taskFinish(item) {
      const res = await get("/task/finish", { task_id: item.Task?.ID });
      if (res.code === 0) {
        localStorage.setItem('currentItem', '');
        this.init();
        this.$toast.success(`You get ${item.Task?.points} points!`);
      } else {
        if (res.msg.indexOf('已经完成') > -1 ||res.msg.indexOf('已完成') > -1) {
          this.$toast.error('Task completed');
        } else {
          this.$toast.error(res.msg);
        }
      }
    },
    setDefaultImg(event){
      event.target.src = routeUrl + 'assets/img/friends/user-img.svg';
    },
    getWallet(value) {
     //console.log('getWallet', value);
    },
    async getUserTiem() {
      const getData = this.processingTime()
      this.unixtime = getData
      this.getMyTaskDailyList();
    },
    processingTime() {
      const utcDate = new Date();
      const year = utcDate.getUTCFullYear();
      const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, '0');
      const day = utcDate.getUTCDate().toString().padStart(2, '0');
      const formattedDate = `${year}${month}${day}`;
      const numericDate = parseInt(formattedDate);
      return numericDate;
    },
    truncateString(str) {
      const firstPart = str.substring(0, 5);
      const lastPart = str.substring(str.length - 4);
      return `${firstPart}....${lastPart}`;
    },
    copyToClipboard(txt, showAddress) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(txt)
          .then(() => {
            console.error('Successfully!');
            this.$toast.success(showAddress);
          })
          .catch((error) => {
            console.error('Copying failed:', error);
            this.copyFallback(txt, showAddress);
          });
      } else {
        this.copyFallback(txt, showAddress);
      }
    },
    copyFallback(txt, showAddress) {
      const textarea = document.createElement('textarea');
      textarea.value = txt;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.$toast.success(showAddress);
    }
  },
  beforeDestroy() {
    if (this.unwatch) {
      this.unwatch();
    }
  },
}
</script>
<style lang="scss" scoped>
.quest-inner{
  position: relative;
  padding:0 .5rem 6rem .5rem;
  .wallet{
    position: absolute;top: 2rem;right: 1rem;
    width: 3rem;
    height: 3rem;
    background: url('@/assets/img/quest/wallet.png') no-repeat center center/contain;
    z-index: 99;
  }
  .user{
    width: 20rem;height: 13rem;
    text-align: center;
    margin: 0 auto;
    padding-top: 1.8rem;
    background: url('@/assets/img/icons/light.png') no-repeat center center/contain;
    position: relative;
    .user-img{
      position: relative;
      z-index: 1;
      left: 0.2rem;
      top: 2.2rem;
      width:4.5rem;
      height:4.5rem;
      margin: 0 auto;
      border-radius: 50%;
      margin-bottom: 2.5rem;
      img{
        width: 100%;height: 100%;
        border-radius: 50%;
      }
    }
    .user-name{
      font-size: 1.2rem;
      font-weight: 700;
      height: 2rem;
      padding-top: .8rem;
    }
    .user-integral{
      width: 50%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3rem;
      margin-top: .3rem;
      img{
        width: 2rem;height: 2rem;
        margin-right: .3rem;
      }
      span{
        font-size: 1rem;
      }
    }
    .user-bg{
      width: 8rem;height: 8rem;
      position: absolute;top:50%;left: 50%;
      transform: translate(-50%,-50%);
      background: url('@/assets/img/ranking/move.png') no-repeat center center/contain;
	    // animation: rotate 14s linear infinite;
    }
    @keyframes rotate {
      0% {
        transform: translate(-50%, -50%) rotate( 0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(-360deg);
      }
    }
  }
  .daily-bot-bg-top{
    height: 4rem;
    width: 100%;
    background: url('@/assets/img/quest/tasks-01-01.png') no-repeat top center/100% 100%;
  }
  .daily-tasks{
    padding: 0 .4rem 0 .4rem;
    margin: -1rem 0 -1px 0;
    background: url('@/assets/img/quest/tasks-01-02.png') repeat-x center center/100% 100%;
  }
  .daily-bot-bg{
    height: 2rem;
    width: 100%;
    background: url('@/assets/img/quest/tasks-01-03.png') no-repeat bottom center/100% 100%;
  }
  .tasks-bot-bg-top{
    height: 4rem;
    width: 100%;
    background: url('@/assets/img/quest/tasks-02-01.png') no-repeat top center/100% 100%;
  }
  .basic-tasks{
    padding: 0 .4rem 0 .4rem;
    margin-top: -1rem;
    background: url('@/assets/img/quest/tasks-02-03.png') repeat-x bottom center/100% 100%;
  }
  .tasks-bot-bg{
    height: 1rem;
    width: 100%;
    margin-top: -1px;
    background: url('@/assets/img/quest/tasks-02-02.png') no-repeat center /100% 100%;
  }
  .quest-title{
    font-size: .9rem;
    font-weight: bold;
    padding-left: 1rem;
    font-style: italic;
    color: #161616;
    position: relative;
    top: -.6rem;
    left: -.3rem;
  }
  .basic-tasks{
    .quest-title{
      top: -1rem;
    }
  }
  .list{
    ul{
      padding:0 .5rem 0 1rem;
      li{
        position: relative;
        margin-bottom: .6rem;
        &:last-child{
          margin-bottom: 0;
        }
      }
      .tasks-li{
        position: relative;
        left: 0;z-index: 2;
        padding: .5rem 1rem;
        border-radius: .5rem;
        // margin: 0.6rem 0;
        background: linear-gradient(to top, #87CEFA 10%, #FFFFFF);
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #161616;
        .listimg{
          width: 3.6rem;height: 3.6rem;
          margin-right: .8rem;
          background: url('@/assets/img/quest/tappop_preview.png') no-repeat center center/contain;
        }
        .name{
          width: 70%;
          font-size: 0.9rem;
          font-weight: bold;
        }
        .listtype{
          color: #161616;
          font-size: 1.2rem;font-weight: bold;border-radius: .5rem;padding: .2rem;
          padding-left: 2rem;
          background: url('@/assets/img/friends/points.png') no-repeat left center/contain;
          font-size: 1rem;
        }
      }
      .tasks-bg{
        position: absolute;bottom: -.15rem;left: 0;
        height: .5rem;
        width: 100%;
        border-radius: 0 0 0.5rem 0.5rem;
        background: #024CCE;
        z-index: 1;
      }
      .tasks-ok{
        position: absolute;
        top: 0;left: 0;
        background: #024CCE;
        opacity: 0.2;
        width: 100%;height: 100%;
        z-index: 3;display: none;
        border-radius: .5rem;
      }
      .tasks-ok-img{
        position: absolute;
        top: 50%;right: 1.5rem;
        transform: translate(0,-50%);
        z-index: 3;
        width: 2.5rem;height: 2.5rem;
        background: url('@/assets/img/quest/tasks.png') no-repeat left center/contain;
        display: none;
      }
      .active{
        .tasks-li{
          background: #024CCE;
          color: #fff;
        }
        .listtype{
          background: url('@/assets/img/friends/points.png') no-repeat left center/contain;
          color: #fff;
        }
        .tasks-ok,
        .tasks-ok-img{
          display: block;
        }
        .tasks-bg{display: none;}
      }
    }
  }
}
</style>
