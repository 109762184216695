'use strict';

//import * as createjs from '../assets/lib/createjs.min.js';

import '../assets/lib/soundjs.min.js';
const routeUrl = process.env.ROUTE_URL || '../';
class AudioManager {
	constructor() {
		this.instances = {};
		this.isOk = 0;
		this.audioConfig = [
			'cool',
			'perfect',
			'success',
			'fail',
			'start',
			'push',
			'push_loop',
		];
		this.SoundJS = createjs.Sound;

		this._registerMusic();
		this._initMusicInstance();
	}

	_registerMusic() {
		this.SoundJS.alternateExtensions = ["mp3"];
		this.SoundJS.on("fileload", () => {
			this.isOk += 1;
			if (this.isOk === 6) console.log("Loading Sound ... 100%");
		});

		this.audioConfig.forEach((c, i) => {
			this.SoundJS.registerSound(routeUrl+`assets/audio/${c}.mp3`, c, i);
		});
	}

	_initMusicInstance() {
		this.audioConfig.forEach((c) => {
			const instance = this.SoundJS.play(routeUrl+`assets/audio/${c}.mp3`);
			this.instances[c] = instance;
		});
	}

	play(key) {
		const ins = this.instances[key];
		ins.volume = 0.7;
		if (key === 'bg') ins.volume = 0.2;
		else if (key === 'cool' || key === 'perfect') ins.volume = 1;
		if (this.musicSwitchFn() == "true") {
			ins.play();
		}
	}

	stop(key) {
		const ins = this.instances[key];
		ins.stop();
	}

	replay(key) {
		const ins = this.instances[key];
		if (this.musicSwitchFn() == "true") {
			ins.play();
		}
	}

	musicSwitchFn() {
		const musicSwitch = localStorage.getItem("musicSwitch") || 'true';
		return musicSwitch;
	}
}

export default AudioManager;
