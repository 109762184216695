<template>
  <div class="nav navMenu" :class="{ 'showNavMenu': showValue }">
    <div class="showNav" @click="updateChildValue"></div>
    <ul>
      <li v-for="(item, index) in list" :key="index" @click="showBlindBox(item.link)" :class="{ 'navActive': item.onname === on }">
        <router-link :to="item.link">
          <div class="nav-img"><img :src="item.img" alt=""></div>
          <div>{{item.name}}</div>
        </router-link>
        <div class="onactive"></div>
      </li>
      <li class="nav-item-bg" :style="{ 'left': navNum + '%' }"></li>
    </ul>
  </div>
</template>

<script>
const routeUrl = process.env.ROUTE_URL || '../';
export default {
  name: 'NavMenu',
  props: {
    showValue: {
      type: Boolean,
      required: true
    },
    on: {
      type: String,
      required: ''
    }
  },
  data() {
    return {
      list: [
        { link: '/home', name: 'Game', onname: 'game', img: routeUrl + 'assets/img/team/team-01.png', on: false, num: 0 },
        { link: '/friends', name: 'Friends', onname: 'friends', img: routeUrl + 'assets/img/team/team-02.png', on: false, num: 25 },
        { link: '/quest', name: 'Quest', onname: 'quest', img: routeUrl + 'assets/img/team/team-03.png', on: false, num: 50 },
        { link: '/ranking', name: 'Ranking', onname: 'ranking', img: routeUrl + 'assets/img/team/team-04.png', on: false, num: 75 }
      ],
      navNum: localStorage.getItem("navNum") || 0,
    }
  },
  mounted() {
    this.list.map(item => {
      if (item.onname === this.on) {
        localStorage.setItem("navNum", item.num)
        setTimeout(() => {
          this.navNum = item.num;
        }, 100);
      }
    });
  },
  methods: {
    showBlindBox(type) {
      if (type != '/home') {
        const canvas = document.getElementById('botgamecanvas');
        const score = document.getElementById('score');
        if (canvas) {
          canvas.remove();
        }
        if (score) {
          score.remove();
        }
      }
    },
    updateParentValue(value) {
      this.parentValue = value;
    },
    updateChildValue() {
      const navMenu = document.querySelector('.navMenu');
		  navMenu.classList.remove('showNavMenu');
      this.$emit('childClick', false);
    }
  }
}
</script>

<style lang="scss" scoped>
/* 样式代码 */
.nav {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 89;
  width: 100%;
  background-color: #024CCE;
  transition: bottom 0.8s ease-in-out;

  ul {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;

    li {
      text-align: center;
      list-style: none;
      width: 33.3%;
      font-size: 0.8rem;
      cursor: pointer;
      margin: 0 .8rem;
      padding: .5rem 0;
      position: relative;
      z-index: 2;
    }

    .nav-item-bg{
      position: absolute;
      top: -10%;
      width: 18.5%;
      height: 110%;
      z-index: 1;
      background: #478afe;
      border-radius: .5rem .5rem 0 0;
      transition: left .5s;
    }

    li:hover {
      color: #fff;
    }

    li .nav-img{
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 1s ease-in-out;
    }

    li img {
      padding: 0;
      margin: 0;
      width: 2.9rem;
    }
    .navActive{
      // background: #D0F8F8;
      .nav-img{
        position: relative;
        top: -8%;
        transform: scale(1.35);
      }
      div{
        color: #fff;
      }
      .onactive{
        position: absolute;
        top: -.9rem;
        left: 0;
        height: 1rem;
        // background: #D0F8F8;
        width: 100%;
        border-radius: .5rem .5rem 0 0;
      }
    }
  }
}
.navMenu ul, .blindBox ul {
  list-style: none;
  padding: 0;
}

.navMenu ul li, .blindBox ul li {
  display: inline-block;
}

.navMenu ul li div, .blindBox ul li div {
  text-align: center;
  color: #fff;font-weight: bold;
  font-family: 'Righteous', sans-serif;
}

.showNavMenu {
  bottom: -7.3rem;
}

.showNav {
  content: "";
  position: absolute;
  top: -6rem;
  left: calc(50% - 2rem);
  width: 0;
  height: 0;
  border-left: 1.5rem solid transparent;
  border-right: 1.5rem solid transparent;
  border-bottom: 1.5rem solid black;
  display: none;
}

.showNavMenu .showNav {
  display: none;
  // display: block;
}
</style>