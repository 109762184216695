<template>
  <div class="home">
    <div class="page startPage">
      <div class="logo" id="logo"></div>
      <div class="loading"></div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { get, post } from "@/lib/API";

export default {
  name: "Home",
  data() {
    return {
      initData: Telegram.WebApp.initData || '',
    };
  },
  mounted() {
    const getToken = localStorage.getItem("token");
    const platform = Telegram.WebApp.platform;
    if (!getToken) {
      this.userLogin();
    }
    this.initializeGame();
  },
  methods: {
    async initializeGame() {
      const routeUrl = process.env.ROUTE_URL || "../";
      const files = [
        "cd.mtl",
        "cd.obj",
        "cheng.mtl",
        "cheng.obj",
        "d_hezi4.mtl",
        "d_hezi4.obj",
        "d_hezi5.mtl",
        "d_hezi5.obj",
        "d_pixiang.mtl",
        "d_pixiang.obj",
        "d_tiaotai.mtl",
        "d_tiaotai.obj",
        "d_xiaoniao.mtl",
        "d_xiaoniao.obj",
        "d_yuanzhu1.mtl",
        "d_yuanzhu1.obj",
        "dangao.mtl",
        "dangao.obj",
        "huaban.mtl",
        "huaban.obj",
        "pingtai1.mtl",
        "pingtai1.obj",
        "q_hezi4.mtl",
        "q_hezi4.obj",
        "q_shudun.obj",
        "q_shudun.mtl",
        "shoutixiang.obj",
        "shoutixiang.mtl",
        "shudun.mtl",
        "shudun.obj",
        "x_fangzhuo.mtl",
        "x_fangzhuo.obj",
        "x_hezi5.mtl",
        "x_hezi5.obj",
        "x_huaban.mtl",
        "x_huaban.obj",
        "x_xigua.mtl",
        "x_xigua.obj",
        "x_yuanzhu1.mtl",
        "x_yuanzhu1.obj",
      ];
      const fetchPromises = files.map((file) => {
        return fetch(routeUrl + "assets/obj/" + file)
          .then((response) => {
            if (response.ok) {
              return response.text();
            } else {
              throw new Error("File not found: " + file);
            }
          })
          .then((data) => {
            // console.log("File loaded: " + file);
          })
          .catch((error) => {
            console.error(error);
          });
      });

      try {
        await Promise.all(fetchPromises);
        // console.log("All files loaded.");
        this.$router.push('/home')
      } catch (error) {
        console.error("Failed to load files:", error);
      }
    },
    userLogin() {
      post("/tguser/login", { initData: this.initData })
        .then((response) => {
          if (response.code === 0) {
            // console.log(response.data);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("userAddress", response.data.user.nickName);
            localStorage.setItem("phone", response.data.user.phone);
            localStorage.setItem("userName", response.data.user.userName);
            localStorage.setItem("user", JSON.stringify(response.data.user));
          }
        })
        .catch((error) => {
         //console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">

.logo {
  width: 100vw;
  height: 37vh;
  margin: 2rem 0;
  background: url('@/assets/img/quest/tappop_preview.png') no-repeat center center/contain;
}

.loading{
  width: 3rem;height: 3rem;
  margin: 0 auto;
  animation: rotate 1s linear infinite;
  background: url('@/assets/img/icons/loading.png') no-repeat center center/contain;

}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
