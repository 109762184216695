import * as THREE from 'three';
import ModelConfig from './modelConfig';
import AudioManager from './audioManager';
//import Tween from '../assets/lib/Tween.js';
import { OBJLoader, MTLLoader } from 'three-obj-mtl-loader';

const TweenFn = require('../assets/lib/Tween.js')
const Tween = new TweenFn()
const routeUrl = process.env.ROUTE_URL || '../';
class Game {
	constructor() {
		this.scene = new THREE.Scene();
		this.group = new THREE.Group();
		this.scene.add(this.group);
		this.camera = new THREE.OrthographicCamera(
			window.innerWidth / -40,
			window.innerWidth / 40,
			window.innerHeight / 40,
			window.innerHeight / -40,
			0.1, 5000,
		);
		this.camera.position.set(100, 100, 100);
		this.camera.lookAt(new THREE.Vector3(0, 0, 0));
		this.cameraPos = {
			current: new THREE.Vector3(0, 0, 0), // The current coordinates of the camera
			next: new THREE.Vector3(), // The position where the camera is about to be moved
		};
		this.cameraSpeed = {
			x: 0,
			y: 0,
			z: 0,
		};
		this.CAMERA_MOVE_TIME = 40;

		this.groupPos = {
			current: null,
			next: null,
		};

		this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize(window.innerWidth, window.innerHeight);
		this.renderer.shadowMap.enabled = true;
		this.renderer.antialias = true;
		const canvas = this.renderer.domElement;
		canvas.id = "botgamecanvas"; // Add custom ID attribute
		document.body.appendChild(canvas);
		this.canvas = canvas;

		// lighting
		const directionalLight = new THREE.DirectionalLight(0xffffff, 0.4);
		directionalLight.position.set(2, 5, -2);
		directionalLight.castShadow = true;
		directionalLight.shadow.camera.near = 0; // The closest distance to generate shadows
		directionalLight.shadow.camera.far = 100; // The farthest distance from which shadows are generated
		const d = 15;
		directionalLight.shadow.camera.left = -d; // The leftmost position where the shadow distance is generated
		directionalLight.shadow.camera.right = d; // rightmost
		directionalLight.shadow.camera.top = d; // At the top
		directionalLight.shadow.camera.bottom = -d; // bottom
		directionalLight.shadow.mapSize.width = 2048;
		directionalLight.shadow.mapSize.height = 2048;
		this.scene.add(directionalLight);
		const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
		this.scene.add(ambientLight);

		this.config = {
			// Bounce body parameter settings
			jumpTopRadius: 0,
			jumpBottomRadius: 0.5,
			jumpHeight: 2,
			jumpColor: 0xFEEE77,
			// Cube parameter settings
			cubeX: 4,
			cubeY: 2,
			cubeZ: 4,
			cubeColor: 0x00ffff,
			// Cylinder parameter settings
			cylinderRadius: 2,
			cylinderHeight: 2,
			cylinderColor: 0x00ff00,
			// Set the maximum number of graphics to be cached in the cache array
			cubeMaxLen: 6,
			// The minimum and maximum distances between the inner edges of a cube
			cubeMinDis: 1,
			cubeMaxDis: 8,

			// Model Config
			modelConfig: new ModelConfig(),
		};

		this.mouse = {
			down: this.isPC() ? 'mousedown' : 'touchstart',
			up: this.isPC() ? 'mouseup' : 'touchend',
		};

		this.cubes = [];
		this.models = [];
		window.models = this.models;
		this.jumper = null;

		// mousedown : -1
		// mouseup : 1
		this.JUMP_FRAME_NUM = 40;
		this.ADDSPEED = 0.005;
		this.accelerate = {
			x: 0, // Horizontal uniform motion
			y: 0.02, // fixed value
			z: 0, // Horizontal uniform motion
		};
		this.speed = {
			x: 0, // The speed in the forward direction increases with the duration of mousedown
			y: this.accelerate.y * this.JUMP_FRAME_NUM / 2, // Fixed value of bounce speed
			z: 0, // Compensate for speed to make the jumper fall on the center axis of the next block
		};
		this.mouseState = 0;
		this.currentFrame = -1;
		this.score = 0;

		this.failCallback = function () {};

		this.audioManager = new AudioManager();
		const audioConfig = [
			'cool',
			'perfect',
			'success',
			'fail',
			'start',
			'push',
			'push_loop',
		];
		audioConfig.map(item => {
			this.audioManager.stop(item);
		});
		// console test
		window.jumper = this.jumper;
		window.models = this.models;
		window.camera = this.camera;
		window.cameraPos = this.cameraPos;
		window.group = this.group;
	}

	// Randomly generate a graphic
	createCube() {
		//Generate Shape
		const cubeType = Math.random() > 0.5 ? 'cube' : 'cylinder';

		const geometry = cubeType === 'cube' ?
			new THREE.CubeGeometry(this.config.cubeX, this.config.cubeY, this.config.cubeZ) :
			new THREE.CylinderGeometry(this.config.cylinderRadius, this.config.cylinderRadius, this.config.cylinderHeight, 100);
		const color = cubeType === 'cube' ? this.config.cubeColor : this.config.cylinderColor;
		const material = new THREE.MeshLambertMaterial({
			color: 0x000,
			transparent: true,
			opacity: 0,
		});
		const mesh = new THREE.Mesh(geometry, material);

		// Generate location
		const relativePos = Math.random() > 0.5 ? 'zDir' : 'xDir';
		if (this.cubes.length) {
			const dis = this.getRandomValue(this.config.cubeMinDis, this.config.cubeMaxDis);
			const lastcube = this.cubes[this.cubes.length - 1];
			if (relativePos === 'zDir') {
				if (cubeType === 'cube') {
					if (lastcube.geometry instanceof THREE.CubeGeometry) {
						// Square Body ->Square Body
						const pos = { x: lastcube.position.x, y: lastcube.position.y, z: lastcube.position.z - dis - this.config.cubeZ };
						this.createModel(pos);
						mesh.position.set(pos.x, pos.y, pos.z);
					} else {
						// Square body ->Cylinder
						const pos = { x: lastcube.position.x, y: lastcube.position.y, z: lastcube.position.z - dis - this.config.cylinderRadius - this.config.cubeZ / 2 };
						this.createModel(pos);
						mesh.position.set(pos.x, pos.y, pos.z);
					}
				} else {
					if (lastcube.geometry instanceof THREE.CubeGeometry) {
						//  Cylinder ->Square
						const pos = { x: lastcube.position.x, y: lastcube.position.y, z: lastcube.position.z - dis - this.config.cylinderRadius - this.config.cubeZ / 2 };
						this.createModel(pos);
						mesh.position.set(pos.x, pos.y, pos.z);
					} else {
						// Cylinder ->Cylinder
						const pos = { x: lastcube.position.x, y: lastcube.position.y, z: lastcube.position.z - dis - this.config.cylinderRadius * 2 };
						this.createModel(pos);
						mesh.position.set(pos.x, pos.y, pos.z);
					}
				}
			} else if (relativePos === 'xDir') {
				if (cubeType === 'cube') {
					if (lastcube.geometry instanceof THREE.CubeGeometry) {
						// Square Body ->Square Body
						const pos = { x: lastcube.position.x + dis + this.config.cubeX, y: lastcube.position.y, z: lastcube.position.z };
						this.createModel(pos);
						mesh.position.set(pos.x, pos.y, pos.z);
					} else {
						// Square body ->Cylinder
						const pos = { x: lastcube.position.x + dis + this.config.cubeX / 2 + this.config.cylinderRadius, y: lastcube.position.y, z: lastcube.position.z };
						this.createModel(pos);
						mesh.position.set(pos.x, pos.y, pos.z);
					}
				} else {
					if (lastcube.geometry instanceof THREE.CubeGeometry) {
						// Cylinder ->Square
						const pos = { x: lastcube.position.x + dis + this.config.cylinderRadius + this.config.cubeX / 2, y: lastcube.position.y, z: lastcube.position.z };
						this.createModel(pos);
						mesh.position.set(pos.x, pos.y, pos.z);
					} else {
						// Cylinder ->Cylinder
						const pos = { x: lastcube.position.x + dis + this.config.cylinderRadius * 2, y: lastcube.position.y, z: lastcube.position.z };
						this.createModel(pos);
						mesh.position.set(pos.x, pos.y, pos.z);
					}
				}
			}
		} else {
			this.createModel({ x: 0, y: 0, z: 0 });
			mesh.position.set(0, 0, 0);
		}

		// Rendering
		this.testPosition(mesh.position);
		this.cubes.push(mesh);
		this.group.add(mesh);
		this._render();
		// If the number of cached graphics exceeds the maximum cache size, remove one
		if (this.cubes.length > this.config.cubeMaxLen) {
			this.group.remove(this.cubes.shift());
		}
		const _this = this;
		if (_this.cubes.length > 1) {
			// Update camera position
			_this._updateCameraPos();
		} else {
			_this.camera.lookAt(this.cameraPos.current);
		}
	}

	// Create a bouncing object
	createJumper() {
		const geometry = new THREE.CylinderGeometry(this.config.jumpTopRadius, this.config.jumpBottomRadius, 1.7, 100);
		const material = new THREE.MeshLambertMaterial({ color: this.config.jumpColor });
		const textureLoader = new THREE.TextureLoader();
		// const texture = textureLoader.load('../assets/img/team-04.png');
		// const material = new THREE.MeshLambertMaterial({ map: texture });
		const mesh = new THREE.Mesh(geometry, material);
		geometry.translate(0, this.config.jumpHeight / 2, 0);
		mesh.position.set(0, this.config.jumpHeight / 2, 0);
		mesh.castShadow = true;
		mesh.receiveShadow = true;
		this.jumper = mesh;
		this.group.add(mesh);
		this._render();
	}

	createModel(position) {
		const _this = this;
		const name = this.getRandomItem(this.config.modelConfig.objList).ele;

		// BUG HERE
		// When there is no Object Create, objConfig is the same, and the position parameters of the second model are overwritten before the first model is loaded，因此两个会重叠在同一位置！
		const objConfig = Object.create(this.config.modelConfig[name]);

		objConfig.position = position;

		// callback
		const addModelToGame = (obj) => {
			// Add Shadow
			obj.children.forEach((element) => {
				element.traverse((o) => {
					if (o.type === 'Mesh') {
						o.castShadow = true;
						o.receiveShadow = true;
					}
				});
			});

			// Set parameters
			obj.scale.x = objConfig.scale.x;
			obj.scale.y = objConfig.scale.y;
			obj.scale.z = objConfig.scale.z;
			obj.rotation.x = objConfig.rotation.x * Math.PI;
			obj.rotation.y = objConfig.rotation.y * Math.PI;
			obj.rotation.z = objConfig.rotation.z * Math.PI;
			obj.position.x = objConfig.position.x;
			obj.position.y = objConfig.position.y;
			obj.position.z = objConfig.position.z;
			_this.group.add(obj);

			// If the number of cached graphics exceeds the maximum cache size, remove one
			_this.models.push(obj);
			if (_this.models.length > _this.config.cubeMaxLen) {
				_this.removeModel(_this.models[0]);
				_this.models.shift();
			}
		};

		// Loaders
		const mtlLoader = new MTLLoader();
		//mtlLoader.load(`./res/obj/${name}.mtl`, (materials) => {
		mtlLoader.load(routeUrl + `assets/obj/${name}.mtl`, (materials) => {
			materials.preload();
			const objLoader = new OBJLoader();
			objLoader.setMaterials(materials);
			objLoader.load(routeUrl + `assets/obj/${name}.obj`, (object) => {
        // Scaling the model, here the model will be
        addModelToGame(object);
				if (name === 'dangao' || name === 'cheng' || name === 'cd' || name === 'q_hezi4' || name === 'x_xigua') {
					object.scale.set(0.3, 0.3, 0.3);
					object.position.y = 0.3;
					this._render();
				} else {
					this._render();
				}
    	});
		});
	}

	removeModel(model) {
		// Delete memory
		model.children.forEach((element) => {
			element.traverse((obj) => {
				if (obj.type === 'Mesh') {
					obj.geometry.dispose();
					if (Array.isArray(obj.material)) {
						obj.material.forEach((element) => {
							element.dispose();
						});
					} else {
						obj.material.dispose();
					}
				}
			});
		});
		// Remove from the scene
		this.group.remove(model);
	}

	createPlane() {
		const planeGeo = new THREE.PlaneGeometry(100, 100, 10, 10); // Create a plan
		const textureLoader = new THREE.TextureLoader();
		const texture = textureLoader.load('@/assets/img/icons/index_bg_03.png', () => {
			// Set texture properties after texture loading is complete
			texture.wrapS = THREE.RepeatWrapping;
			texture.wrapT = THREE.RepeatWrapping;
			texture.repeat.set(1, 1); // Control the number of texture repetitions
	});
		const planeMat = new THREE.MeshLambertMaterial({ // Create materials
			// map: texture,
			color: 0x7A4DFF,
			wireframe: false,
		});
		const planeMesh = new THREE.Mesh(planeGeo, planeMat); // Create a grid model
		planeMesh.position.set(0, -this.config.cubeY / 2, 0); // Set the coordinates of the plane
		planeMesh.rotation.x = -0.5 * Math.PI; // Rotate the plane counterclockwise by 90 degrees around the X-axis
		planeMesh.receiveShadow = true; // Allow receiving shadows
		this.scene.add(planeMesh); // Add a plane to the scene

		this.audioManager.play('start');
	}

	_render() {
		this.renderer.render(this.scene, this.camera);
	}

	_updateCameraPos() {
		const a = this.cubes[this.cubes.length - 2];
		const b = this.cubes[this.cubes.length - 1];
		const dis = {
			x: b.position.x - a.position.x,
			y: 0,
			z: b.position.z - a.position.z,
		};
		this.groupPos.current = {
			x: this.group.position.x,
			y: this.group.position.y,
			z: this.group.position.z,
		};
		this.groupPos.next = {
			x: this.group.position.x - dis.x,
			y: 0,
			z: this.group.position.z - dis.z,
		};
		this._updateCamera(0);
	}

	_updateCamera(frame) {
		if (frame > this.CAMERA_MOVE_TIME) {
			return;
		} else frame += 1;

		const dir = this.getDirection();
		if (dir === 'x') {
			const dis = Tween.Quart.easeInOut(frame, this.groupPos.current.x, this.groupPos.next.x - this.groupPos.current.x, this.CAMERA_MOVE_TIME);
			this.group.position.x = dis;
		} else if (dir === 'z') {
			const dis = Tween.Quart.easeInOut(frame, this.groupPos.current.z, this.groupPos.next.z - this.groupPos.current.z, this.CAMERA_MOVE_TIME);
			this.group.position.z = dis;
		}

		this._render();

		requestAnimationFrame(() => {
			this._updateCamera(frame);
		});
	}

	_registerEvent() {
		this.canvas.addEventListener(this.mouse.down, this._onMouseDown.bind(this));
		this.canvas.addEventListener(this.mouse.up, this._onMouseUp.bind(this));
		window.addEventListener('resize', this._onwindowResize.bind(this), false);
	}

	_destroyEvent() {
		this.canvas.removeEventListener(this.mouse.down, this._onMouseDown.bind(this));
		this.canvas.removeEventListener(this.mouse.up, this._onMouseUp.bind(this));
		window.removeEventListener('resize', this._onwindowResize.bind(this), false);
	}

	_onwindowResize() {
		this.camera.left = window.innerWidth / -80;
		this.camera.right = window.innerWidth / 80;
		this.camera.top = window.innerHeight / 80;
		this.camera.bottom = window.innerHeight / -80;
		this.camera.updateProjectionMatrix();

		this.renderer.setSize(window.innerWidth, window.innerHeight);
	}

	_onMouseDown() {
		const navMenu = document.querySelector('.navMenu');
		navMenu.classList.add('showNavMenu');
		this.mouseState = -1;
		if (this.jumper.scale.y > 0.2) {
			this.jumper.scale.y -= 0.01;
			this.speed.x += this.ADDSPEED;
			this.speed.z = this.getNextDistance().z / this.JUMP_FRAME_NUM;
			this._render();
			requestAnimationFrame(() => {
				if (this.mouseState === -1) this._onMouseDown();
			});
		}
		this.audioManager.play('push');
	}

	_onMouseUp() {
		const self = this;
		this.mouseState = 1;
		this.audioManager.stop('push');
		if (this.jumper.position.y >= this.config.jumpHeight / 2) {
			// jumper Still moving in the air
			this.currentFrame += 1;
			const dir = this.getDirection();
			if (dir === 'x') {
				this.jumper.position.x += this.speed.x;
				this.jumper.position.y += this.speed.y;
				this.jumper.position.z += this.speed.z;
				this.jumper.rotation.z = this.getRotation();
			} else {
				this.jumper.position.z -= this.speed.x;
				this.jumper.position.y += this.speed.y;
				this.jumper.position.x += this.speed.z;
				this.jumper.rotation.x = this.getRotation();
			}
			this._render();
			// Vertical direction first rises and then falls
			this.speed.y -= this.accelerate.y;
			// jumper To restore
			if (this.jumper.scale.y < 1) {
				this.jumper.scale.y += 0.02;
			}
			requestAnimationFrame(() => {
				this._onMouseUp();
			});
		} else {
			// jumper It's landed
			const type = this.getJumpState();
			this.resetJumper();
			if (type === 1) {
				// Falling on the current block
			} else if (type === 2) {
				// Successfully landed
				this._updateScore(10);
				this.createCube();
				this.audioManager.play('success');
			} else if (type === 3) {
				// Perfect Landing Center
				this._updateScore(30);
				this.createCube();
				this.audioManager.play('success');
				this.audioManager.play(this.getRandomItem(['cool', 'perfect']).ele);
			} else if (type === -2) {
				// Falling to the Earth Animation
				this.audioManager.play('fail');
				function continueFalling() {
					if (self.jumper.position.y >= -self.config.jumpHeight / 2) {
						self.jumper.position.y -= 0.06;
						self._render();
						requestAnimationFrame(continueFalling);
					}
				}
				continueFalling();
				if (this.failCallback) {
					setTimeout(() => {
						self.failCallback(self.score);
					}, 1000);
				}
			} else {
				// Falling to the edge
				this.audioManager.play('fail');
				this.fallingAnimation(type);
				if (this.failCallback) {
					setTimeout(() => {
						self.failCallback(self.score);
					}, 1000);
				}
			}
		}
	}

	_initScore() {
		let el = document.querySelector('#score');
		if (el) {
			el.innerHTML = '0';
		} else {
			el = document.createElement('div');
			el.id = 'score';
			el.innerHTML = '0';
			document.body.appendChild(el);
		}
	}

	_updateScore(digit) {
		//  toast
		const t = document.querySelector('.MyToast');
		// const blindBox = document.querySelector('.blindBox');
		// const goblindbox = document.querySelector('.goblindbox');

		t.innerHTML = `+${digit}`;
		t.classList.remove('disappear');
		setTimeout(() => {
			t.classList.add('disappear');
		}, 250);
		// Increase score
		this.score += digit;
		// if (this.score > 0) {
		// 	blindBox.style.display = 'block';
		// 	if (this.score <= 10) {
		// 		goblindbox.innerHTML = 'Level 1 blind box';
		// 	}
		// 	if (this.score > 10 && this.score <= 20) {
		// 		goblindbox.innerHTML = 'Level 2 blind box';
		// 	}
		// 	if (this.score > 20) {
		// 		goblindbox.innerHTML = 'Level 3 blind box';
		// 	}
		// }
		document.getElementById('score').innerHTML = this.score;
	}

	start() {
		this.createPlane();
		this.createCube();
		this.createCube();
		this.createJumper();
		this._registerEvent();
		this._initScore();
	}

	restart() {
		for (let i = 0, len = this.cubes.length; i < len; i++) {
			this.group.remove(this.cubes[i]);
		}
		for (let i = 0, len = this.models.length; i < len; i++) {
			this.removeModel(this.models[i]);
		}
		this.models.length = 0;
		this.group.remove(this.jumper);
		this.group.position.x = 0;
		this.group.position.z = 0;

		this.cameraPos = {
			current: new THREE.Vector3(0, 0, 0), // The current coordinates of the camera
			next: new THREE.Vector3(), // The position where the camera is about to be moved
		};
		this.cubes = [];
		this.jumper = null;
		this.mouseState = 0;
		this.xspeed = 0;
		this.yspeed = 0;
		this.score = 0;

		this.createCube();
		this.createCube();
		this.createJumper();
		this._initScore();
		this.audioManager.play('start');

	}

	resetJumper() {
		this.currentFrame = -1;
		this.jumper.scale.y = 1;
		this.jumper.position.y = this.config.jumpHeight / 2;
		this.jumper.rotation.x = 0;
		this.jumper.rotation.z = 0;
		this.speed.x = 0;
		this.speed.y = this.accelerate.y * this.JUMP_FRAME_NUM / 2;
		this.speed.z = 0;
	}

	stop() {}

	getRandomValue(min, max) {
		// min <= value < max
		return Math.floor(Math.random() * (max - min)) + min;
	}

	getRandomItem(list) {
		const random_i = this.getRandomValue(0, list.length);
		return {
			i: random_i,
			ele: list[random_i],
		};
	}

	fallingAnimation(state) {
		// console.log('***fallingAnimation***',this)
		let that = this;
		const rotateAxis = this.getDirection() === 'z' ? 'x' : 'z';
		let rotateAdd;
		let rotateTo;
		if (state === -1) {
			rotateAdd = this.jumper.rotation[rotateAxis] - 0.1;
			rotateTo = this.jumper.rotation[rotateAxis] > -Math.PI / 2;
		} else {
			rotateAdd = this.jumper.rotation[rotateAxis] + 0.1;
			rotateTo = this.jumper.rotation[rotateAxis] < Math.PI / 2;
		}
		if (rotateTo) {
			this.jumper.rotation[rotateAxis] = rotateAdd;
			this._render();
			requestAnimationFrame(() => {
				this.fallingAnimation(state);
			});
		} else {
			function continueFalling() {
				// console.log('****continueFalling*****this',that)
				if (that.jumper.position.y >= -that.config.jumpHeight / 2) {
					that.jumper.position.y -= 0.06;
					that._render();
					requestAnimationFrame(continueFalling);
				}
			}
			continueFalling();
		}
	}

	getJumpState() {
		const jumpR = this.config.jumpBottomRadius;
		const vard = this.getCurrentDistance();
		const d = vard.d;
		const d1 = vard.d1;
		const d2 = vard.d2;
		const d3 = vard.d3;
		const d4 = vard.d4;
		if (d <= d1) {
			return 1;
		} else if (d > d1 && Math.abs(d - d1) <= jumpR) {
			return -1;
		} else if (Math.abs(d - d1) > jumpR && d < d2 && Math.abs(d - d2) >= jumpR) {
			return -2;
		} else if (d < d2 && Math.abs(d - d2) < jumpR) {
			return -3;
		} else if (d > d2 && d <= d4) {
			// Perfect landing point
			if (d >= (d3 - 0.2) && d <= (d3 + 0.2)) {
				return 3;
			}
			return 2;
		} else if (d > d4 && Math.abs(d - d4) < jumpR) {
			return -1;
		}
		return -2;
	}

	getCurrentDistance() {
		let d;
		let d1;
		let d2;
		let d3;
		let d4;
		const fromObj = this.cubes[this.cubes.length - 2];
		const fromPosition = fromObj.position;
		const fromType = fromObj.geometry instanceof THREE.CubeGeometry ? 'cube' : 'cylinder';
		const toObj = this.cubes[this.cubes.length - 1];
		const toPosition = toObj.position;
		const toType = toObj.geometry instanceof THREE.CubeGeometry ? 'cube' : 'cylinder';
		const jumpObj = this.jumper;
		const position = jumpObj.position;

		if (fromType === 'cube') {
			if (toType === 'cube') {
				if (fromPosition.x === toPosition.x) {
					// -z direction
					d = Math.abs(position.z);
					d1 = Math.abs(fromPosition.z - this.config.cubeZ / 2);
					d2 = Math.abs(toPosition.z + this.config.cubeZ / 2);
					d3 = Math.abs(toPosition.z);
					d4 = Math.abs(toPosition.z - this.config.cubeZ / 2);
				} else {
					// x direction
					d = Math.abs(position.x);
					d1 = Math.abs(fromPosition.x + this.config.cubeX / 2);
					d2 = Math.abs(toPosition.x - this.config.cubeX / 2);
					d3 = Math.abs(toPosition.x);
					d4 = Math.abs(toPosition.x + this.config.cubeX / 2);
				}
			} else {
				if (fromPosition.x === toPosition.x) {
					// -z direction
					d = Math.abs(position.z);
					d1 = Math.abs(fromPosition.z - this.config.cubeZ / 2);
					d2 = Math.abs(toPosition.z + this.config.cylinderRadius);
					d3 = Math.abs(toPosition.z);
					d4 = Math.abs(toPosition.z - this.config.cylinderRadius);
				} else {
					// x direction
					d = Math.abs(position.x);
					d1 = Math.abs(fromPosition.x + this.config.cubeX / 2);
					d2 = Math.abs(toPosition.x - this.config.cylinderRadius);
					d3 = Math.abs(toPosition.x);
					d4 = Math.abs(toPosition.x + this.config.cylinderRadius);
				}
			}
		} else if (toType === 'cube') {
			if (fromPosition.x === toPosition.x) {
				// -z direction
				d = Math.abs(position.z);
				d1 = Math.abs(fromPosition.z - this.config.cylinderRadius);
				d2 = Math.abs(toPosition.z + this.config.cubeZ / 2);
				d3 = Math.abs(toPosition.z);
				d4 = Math.abs(toPosition.z - this.config.cubeZ / 2);
			} else {
				// x direction
				d = Math.abs(position.x);
				d1 = Math.abs(fromPosition.x + this.config.cylinderRadius);
				d2 = Math.abs(toPosition.x - this.config.cubeX / 2);
				d3 = Math.abs(toPosition.x);
				d4 = Math.abs(toPosition.x + this.config.cubeX / 2);
			}
		} else if (fromPosition.x === toPosition.x) {
			// -z direction
			d = Math.abs(position.z);
			d1 = Math.abs(fromPosition.z - this.config.cylinderRadius);
			d2 = Math.abs(toPosition.z + this.config.cylinderRadius);
			d3 = Math.abs(toPosition.z);
			d4 = Math.abs(toPosition.z - this.config.cylinderRadius);
		} else {
			// x direction
			d = Math.abs(position.x);
			d1 = Math.abs(fromPosition.x + this.config.cylinderRadius);
			d2 = Math.abs(toPosition.x - this.config.cylinderRadius);
			d3 = Math.abs(toPosition.x);
			d4 = Math.abs(toPosition.x + this.config.cylinderRadius);
		}

		return { d, d1, d2, d3, d4 };
	}

	getNextDistance() {
		const fromObj = this.cubes[this.cubes.length - 2];
		const fromPosition = fromObj.position;
		const fromType = fromObj.geometry instanceof THREE.CubeGeometry ? 'cube' : 'cylinder';

		const toObj = this.cubes[this.cubes.length - 1];
		const toPosition = toObj.position;
		const toType = toObj.geometry instanceof THREE.CubeGeometry ? 'cube' : 'cylinder';

		const jumpObj = this.jumper;
		const position = jumpObj.position;

		const direction = this.getDirection();
		const distance = {
			x: 0, 
			y: 0, 
			z: 0,
		};
		if (direction === 'x') {
			distance.z = toPosition.z - position.z;
		} else if (direction === 'z') {
			distance.z = toPosition.x - position.x;
		}
		return distance;
	}

	getDirection() {
		let direction;
		if (this.cubes.length > 1) {
			const from = this.cubes[this.cubes.length - 2];
			const to = this.cubes[this.cubes.length - 1];
			if (from.position.z === to.position.z) direction = 'x';
			if (from.position.x === to.position.x) direction = 'z';
		}
		return direction;
	}

	getRotation() {
		const time = this.currentFrame;
		return -Tween.Quint.easeInOut(time, 0, 2 * Math.PI, 40);
	}

	testPosition(position) {
		if (isNaN(position.x) || isNaN(position.y) || isNaN(position.z)) {
			// console.log('position incorrect！');
		}
	}

	isPC() {
		const userAgentInfo = navigator.userAgent;
		const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
		let flag = true;
		for (let v = 0; v < Agents.length; v++) {
			if (userAgentInfo.indexOf(Agents[v]) > 0) {
				flag = false;
				break;
			}
		}
		return flag;
	}
}

export default Game;
