<template>
  <div class="friends">
    <div class="friends-inner overflowyauto">
      <div class="friends-list" ref="friendslistdata">
        <div class="friends-header-ul">
          <ul>
            <li v-for="(friend, index) in friends" :key="index">
              <div class="friends-avatar">
                <img v-if="friend.TelegramUser?.avatar" :src=friend.TelegramUser?.avatar class="avatar-img" alt="" srcset="" @error="setDefaultImg">
                <img v-else :src='userImg' class="avatar-img" alt="" srcset="">
                <div class="friends-name">
                  {{  friend.username }}
                </div>
              </div>
              <div class="friends-num">
                <Tooltip :content="friend.TelegramUser?.task_points + friend.TelegramUser?.game_points ? friend.TelegramUser?.task_points + friend.TelegramUser?.game_points : 0" position="left">
                  {{ formatNumber(friend.TelegramUser?.task_points + friend.TelegramUser?.game_points)}}
                </Tooltip>
              </div>
            </li>
          </ul>
          <div v-if="loading" class="data-loading">Loading...</div>
          <div ref="loading" class="data-loading-placeholder"></div>
        </div>
        <div class="nodata" v-if="!friends.length">No Data!</div>
        <div class="friends-refresh" :class="{ 'updata-list': isUpdata }" @click="getMyFriendList(1)"></div>
      </div>
      <div class="friends-gift" ref="giftlistdata">
        <div class="friends-gift-header">
          <ul>
            <li v-for="(friend, index) in friendsGift" 
            :key="index" 
            :class="{ 'active': friend.TaskFinished?.telegramId, 'goclaimed': count >= friend.Task?.points,
              'iscompleteok': friend.TaskFinished?.isClaimed
            }"
            @click="claimPoints(friend.TaskFinished, index, friend.Task)"
            >
              <div class="gift-li">
                <div class="gift-gift">x{{ formatNumber(friend.Task?.points) }}</div>
                  <div class="gift-tdes" v-if="friend.Task?.icon != ''" :style="{ backgroundImage: 'url(' + friend.Task?.icon + ')' }"></div>
                  <div class="gift-num">
                    <div class="progress-bar">
                      <div class="progress" :style="{width: (friendsNum/friend.Task?.attrValue) * 100 + '%'}"></div>
                    </div>
                    invite {{ friendsNum + '/' + friend.Task?.attrValue }}
                  </div>
              </div>
              <div class="completeok-m"> </div>
              <div class="completeok"> </div>
            </li>
          </ul>
          <div v-if="giftloading" class="data-loading">Loading...</div>
          <div ref="giftloading" class="data-loading-placeholder"></div>
        </div>
        <div class="friends-refresh friends-refresh-bot" @click="copyText()"></div>
        <div class="friends-footer">
          <div class="friends-btn" :class="{ 'image-animation': isAnimating }" @click="goTelegramBot()"></div>
        </div>
      </div>
    </div>
    <nav-menu on="friends" :showValue="false"></nav-menu>
  </div>
</template>

<script>
import { ref } from 'vue';
import NavMenu from '@/components/NavMenu.vue'
import {get, post} from "@/lib/API";
import Tooltip from '@/components/Tooltip.vue'


const routeUrl = process.env.ROUTE_URL || '../';
export default {
  name: 'Friends',
  components: {
    NavMenu,
    Tooltip
  },
  data() {
    return {
      fixedText: process.env.TG_LINK || '',
      phone: localStorage.getItem("phone"),
      userImg: routeUrl + 'assets/img/friends/user-img.svg',
      friends: localStorage.getItem("getMyFriendList") ? JSON.parse(localStorage.getItem("getMyFriendList")) : [],
      page: 1,
      pageSize: 10,
      count: 0,
      isUpdata: false,
      friendsGift: localStorage.getItem("friendsGift") ? JSON.parse(localStorage.getItem("friendsGift")) : [],
      friendsNum: 0,
      isAnimating: true,
      loading: false,
      giftloading: false,
      giftpage: 1,
      giftpageSize: 10,
      giftcount: 0,
    }
  },
  setup() {
    return {
    }
  },
  mounted() {
    this.initializeGame();
    this.getMyFriendList(1);
    this.getMyTaskList();
    this.getTgUserData();
    const timer = setTimeout(() => {
      this.isAnimating = false;
      clearTimeout(timer);
    }, 1000);
    
    const options = {
      root: this.$refs.friendslistdata,
      rootMargin: '0px',
      threshold: 1.0
    };

    const giftoptions = {
      root: this.$refs.giftlistdata,
      rootMargin: '0px',
      threshold: 1.0
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
      if (entry.isIntersecting) {
          this.handleScroll();
        }
      });
    }, options);

    this.giftbserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
      if (entry.isIntersecting) {
          this.giftHandleScroll();
        }
      });
    }, giftoptions);

    this.$nextTick(() => {

      const loadingElement = this.$refs.loading;
      if (loadingElement) {
        this.observer.observe(loadingElement);
      }
      
      const giftloadingElement = this.$refs.giftloading;
      if (giftloadingElement) {
        this.giftbserver.observe(giftloadingElement);
      }

    });

  },
  methods: {
    handleScroll() {
      if (this.loading) return;
      this.loadNextPage();
    },
    giftHandleScroll() {
      if (this.giftloading) return;
      this.giftLoadNextPage();
    },
    initializeGame() {
      // console.log('initialize game');
    },
    goTelegramBot() {
      this.isAnimating = true;
      const timer = setTimeout(() => {
        this.isAnimating = false;
        clearTimeout(timer);
        const url = this.fixedText + '?start=' + this.phone;
        const text = `🎮✨ Join the TapPop game! ✨🎮  Play games, complete tasks, and share to earn points. Get a chance to win USDT and other rewards! 💰🎁  Come and challenge yourself! Let's play and earn together!`;
        const telegramShareUrl = `https://t.me/share/url?url=${url}&text=${text}`;
        if (Telegram.WebApp) {
          Telegram.WebApp.openTelegramLink(telegramShareUrl)
        }
      }, 400);
    },
    getMyFriendList(page) {
      this.page = page;
      this.isUpdata = true;
      this.loading = true;
      const timer = setTimeout(() => {
        this.isUpdata = false;
        clearTimeout(timer);
      }, 1000);
      get('/friend/getMyFriendList', { page: page, pageSize: this.pageSize, type: 'friend' })
        .then(response => {
          if (response.code === 0) {
            const list = response.data.list || [];
            list.map(item => {
              const name = item.TelegramUser?.username ? item.TelegramUser?.username : item.TelegramUser?.first_name
              if (name && name.length > 9) {
                item.username = this.truncateString(name);
              } else {
                item.username = name;
              }
            })
            this.friends = list;
            localStorage.setItem("getMyFriendList", JSON.stringify(list));
            this.count = response.data.total;
            this.loading = false;
          }
        })
        .catch(error => {
         //console.log(error)
        });
    },
    async loadNextPage() {
      if (this.page * this.pageSize < this.count) {
        this.loading = true;
        this.page += 1;
        const response = await get('/friend/getMyFriendList', { page: this.page, pageSize: this.pageSize, type: 'friend' });
        if (response.code === 0) {
          this.friends.push(...response.data.list)
          this.loading = false;
        }
      }
    },
    async giftLoadNextPage() {
      if (this.giftpage * this.giftpageSize < this.giftcount) {
        this.giftloading = true;
        this.giftpage += 1;
        const response = await get('/task/getMyTaskList', { page: this.giftpage, pageSize: this.giftpageSize, type: 'friend' });
        if (response.code === 0) {
          this.friendsGift.push(...response.data.list)
          this.giftloading = false;
        }
      }
    },
    getMyTaskList() {
      this.giftloading = true;
      get('/task/getMyTaskList', { page: this.giftpage, pageSize: this.giftpageSize, type: 'friend' })
        .then(response => {
          if (response.code === 0) {
           //console.log(response.data)
            this.friendsGift = response.data.list || [];
            this.giftcount = response.data.total;
            localStorage.setItem("friendsGift", JSON.stringify(response.data.list))
            this.giftloading = false;
          }
        })
        .catch(error => {
         //console.log(error)
        });
    },
    claimPoints(taskFinished, index, task) {
      if (!taskFinished.isClaimed) {
        if (!taskFinished.telegramId) {
          this.friendsGift[index].TaskFinished.telegramId = true;
          const timer = setTimeout(() => {
            this.friendsGift[index].TaskFinished.telegramId = '';
            clearTimeout(timer);
          }, 700);
          this.$toast.error(`You don't meet the criteria for claiming rewards`);
        } else {
          window.Telegram.WebApp.showConfirm(`Do you confirm to claim the reward?`, (result) => {
            if (result) {
              this.taskClaimPoints(task);
            } else {
              return false;
            }
          });
        }
      } else {
        this.$toast.error(`You have already claimed the rewards`);
      }
    },
    taskClaimPoints(task) {
      get('/task/claimPoints', { task_id: task?.ID, points: task?.points,  })
        .then(response => {
          if (response.code === 0) {
           //console.log(response.data)
            this.giftpage = 1;
            this.giftpageSize = 10;
            this.getMyTaskList();
            this.$toast.success(`You get ${task?.points} points!`);
          } else {
           //console.log(response)
            this.$toast.error(response.msg);
          }
        })
        .catch(error => {
         //console.log(error)
        });
    },
    setDefaultImg(event){
      event.target.src = routeUrl + 'assets/img/friends/user-img.svg';
    },
    copyText() {
      const txt = this.fixedText + '?start=' + this.phone;
      this.copyToClipboard(txt);
    },
    async getTgUserData() {
      const res = await get("/tguser/myTelegramUser");
      if (res.code === 0) {
        const user = res.data.user;
        this.friendsNum = user.friend_invited;
        localStorage.setItem("avatar", user.avatar);
      }
    },
    copyToClipboard(txt) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(txt)
          .then(() => {
            console.error('Successfully!');
            this.$toast.success('Invite link copied successfully✨');
          })
          .catch((error) => {
            console.error('Copying failed:', error);
            this.copyFallback(txt);
          });
      } else {
        this.copyFallback(txt);
      }
    },
    copyFallback(txt) {
      const textarea = document.createElement('textarea');
      textarea.value = txt;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.$toast.success('Invite link copied successfully✨');
    },
    truncateString(str) {
      const firstPart = str.substring(0, 3);
      const lastPart = str.substring(str.length - 3);
      return `${firstPart}...${lastPart}`;
    },
    formatNumber(value) {
      if (value >= 100000) {
        const roundedValue = Math.round(value / 1000);
        return `${roundedValue}k`;
      } else {
        return value;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.friends{
  padding: .5rem;
  color: #301804
}
.friends-inner{
  font-size: 1.2rem;
  padding-bottom: 7rem;
  .friends-title{
    font-size: 1.3rem;font-weight: bold;font-style: italic;
    }
  .friends-des{
    font-size: .8rem;
    color: #301804;
  }
  .friends-header{
    background: #0C3DED;
    border-radius: 0 0 1rem 1rem;
    padding:1rem 1.6rem;
     display: flex;
     justify-content: space-between;
     align-items: center;
     color: #301804;
  }
  .friends-list{
    padding: 4.5rem 12% 3% 19%;
    height: 15.5rem;
    overflow-y: auto;
    position: relative;
    background: url('@/assets/img/friends/friends-bg_01.png') no-repeat center center / 100% 100%;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .friends-header-ul{
      height: 100%;
      overflow-y: auto;
      padding: 1%;
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      li{
        display: flex;
        align-items: center;
        justify-content:space-between;
        border-bottom: .2rem solid #DDC294;
        padding: .5rem 0;
        background: #FFF4D5;
        border-radius: .5rem;
        box-shadow: 0px 4px 10px 0px rgba(147, 106, 13, 0.4);
        font-weight: bold;
        .friends-avatar{
           border-radius: 50%;
          display: flex;
          align-items: center;
           background-size: cover;
           background-position: center;
           font-size: .9rem;
           .avatar-img{
            width: 2.3rem;height: 2.3rem;
            margin: 0 .6rem;
            border-radius: 50%;
            border: .15rem solid #024CCE;
           }
         }
        .friends-num{
           font-size: 1rem;
           color: #301804;
            background: url('@/assets/img/friends/points.png') no-repeat left center / 1.6rem;
            min-width: 50%;
            padding-left: 2rem;height: 1.6rem;
            line-height: 1.7rem;
         }
      }
    }
    .nodata{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.2rem;
      color: #A5A5A5;
    }
  }
  .friends-gift{
    padding: 3% 11% 22% 19%;
    position: relative;
    background: url('@/assets/img/friends/friends-bg_02.png') no-repeat center center / 100% 100%;
    .gift-gift{
      width: 3.8rem;height: 3.8rem;
      background: url('@/assets/img/friends/user.png') no-repeat center center / 100%;
      padding-top:2.6rem;
      text-align: center;
      font-size: .7rem;
      font-weight: bold;
    }
    .friends-gift-header{
      padding:3% 1% 3% 2%;
      height: 32vh;overflow-y: auto;
      li{
        position: relative;
        margin-bottom: .4rem;
        &:nth-child(2){
            .gift-tdes{
              background-image: url('@/assets/img/icons/friends-bg-03.png');
            }
            .gift-prize{
              background-image: url('@/assets/img/icons/friends-bg-04.png');
            }
          }
          &:nth-child(3){
            .gift-tdes{
              background-image: url('@/assets/img/icons/friends-bg-05.png');
            }
            .gift-prize{
              background-image: url('@/assets/img/icons/friends-bg-06.png');
            }
          }
      }
      .gift-li{
        position: relative;
        display: flex;
        align-items: center;
        justify-content:space-between;
        height: 3.3rem;
        filter: grayscale(100%);
        transition: all .3s;
        .gift-tdes{
           font-weight: bold;
            background: url('@/assets/img/icons/friends-bg-01.png') no-repeat center center / 100%;
            color: #fff;
            height: 100%;
            width: 50%;
            display: flex;
            font-size: 0.6rem;
            padding-left: 16%;
            line-height: 3.3rem;
         }
        .gift-num{
          width: 23%;
          color: #301804;
          font-weight: bold;
          font-size: 0.5rem;
          padding-right: 3%;
          text-align: center;margin-top: 5%;
            .progress-bar {
              width: 100%;
              height: 0.7rem;
              background-color: #FFDAB9;
              border-radius: 0.35rem;
              overflow: hidden;
            }
            .progress {
              height: 100%;
              background-color: #FF8C00;
              transition: width 0.5s ease-in-out;
            }
         }
        .gift-prize{
          display: flex;
          border-radius: 50%;
          flex-wrap: wrap;
          justify-content: center;
          height: 7rem;width: 7rem;
          padding-top: 3.8rem;
          background: url('@/assets/img/icons/friends-bg-02.png') no-repeat center center / 100%;
          .prize-name{
            font-size: .8rem;
            color: #301804;
            text-align: center;
          }
        }
      }
        .completeok-m{
          position: absolute;
          top: 0;
          left: 0;
          z-index: 6;
          width: 100%;height: 100%;
          background: rgba($color: #FCAF01, $alpha: 0.1);
          display: none;
        }
        .completeok{
          position: absolute;
          top: -.5rem;
          left: -.5rem;
          z-index: 8;
          width: 100%;height: 100%;
          background: url('@/assets/img/icons/paperclip.png') no-repeat left top / 2rem 2rem,
          url('@/assets/img/icons/paperclip-02.png') no-repeat 96% center / 2rem 2rem;
          display: none;
        }
      .active,
      .goclaimed{
        .gift-li{
          filter: grayscale(0%);
        }
      }
      .iscompleteok{
        .gift-li{
          opacity: 0.5;
        }
        .completeok, .completeok-m{
          display: block;
        }
      }
    }
    .friends-gift-header::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  .friends-footer{
    display: flex;
    justify-content: space-between;
    height: 3rem;
    position: absolute;
    bottom: 12%;
    left: 0;
    width: 100%;
    .friends-btn{
      width: 34%;
      height: 90%;
      background: url('@/assets/img/friends/friends-btn.png') no-repeat center center / 100%;
      margin: 1.3rem auto 0;
    }
  }
  .friends-refresh{
    position: absolute;top: 1.7rem;right: 2rem;
    background: url('@/assets/img/icons/list-Refresh.png') no-repeat center center / 100% 100%;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    z-index: 9;
  }
  .friends-refresh-bot{
    top: -.5rem;right: 2rem;
    background: url('@/assets/img/icons/copy.png') no-repeat center center / 100% 100%;
  }
  .updata-list{
  animation: rotate 1s linear infinite;
  }
  @keyframes rotate {
    0% {
      transform: rotate( 0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.data-loading{
  text-align: center;
  font-size: 1rem;
  color: #a2a2a2;
  height: 2rem;
}
</style>
